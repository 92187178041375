// StaffAttendanceView.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiUrl from './apiConfig';
import './css/tableattendance.css';
import useAuth from './useAuth';

const StaffAttendanceView = () => {
  const { checkTokenExpiration } = useAuth();


  const [staffAttendanceData, setStaffAttendanceData] = useState([]);
  const [departments, setDepartments] = useState(['All']);
  const [jobTitles, setJobTitles] = useState(['All']);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('All');
  const [selectedJobTitle, setSelectedJobTitle] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const attendancePerPage = 5; // Adjust the number of items per page

  useEffect(() => {
    const fetchStaffAttendanceData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/staffattendanceview`, {
          params: {
            date: selectedDate,
            department: selectedDepartment,
            job_title: selectedJobTitle,
          },
        });

        // Sort the data by staff name
        const sortedData = response.data.sort((a, b) => a.staff_name.localeCompare(b.staff_name));

        setStaffAttendanceData(sortedData);

        // Extract unique departments and job titles from the sorted response
        const uniqueDepartments = ['All', ...new Set(sortedData.map((attendance) => attendance.department))];
        const uniqueJobTitles = ['All', ...new Set(sortedData.map((attendance) => attendance.job_title))];
        setDepartments(uniqueDepartments);
        setJobTitles(uniqueJobTitles);
      } catch (error) {
        console.error('Error fetching staff attendance data:', error);
      }
    };

    fetchStaffAttendanceData();
  }, [selectedDate, selectedDepartment, selectedJobTitle]);

  // Pagination Logic
  const indexOfLastAttendance = currentPage * attendancePerPage;
  const indexOfFirstAttendance = indexOfLastAttendance - attendancePerPage;
  const currentAttendance = staffAttendanceData
  .sort((a, b) => a.staff_name.localeCompare(b.staff_name))
  .slice(indexOfFirstAttendance, indexOfLastAttendance);


  const renderStaffAttendance = currentAttendance.map((attendance, index) => (
    <tr key={attendance.id}>
      <td>{indexOfFirstAttendance + index + 1}</td>
      <td>{attendance.staff_name || 'N/A'}</td>
      <td>{attendance.employee_id}</td>
      <td>{attendance.date}</td>
      <td>{attendance.status}</td>
      <td>{attendance.department}</td>
      <td>{attendance.job_title}</td>
    </tr>
  ));

  // Pagination Controls
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(staffAttendanceData.length / attendancePerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => (
    <li key={number}>
      <button onClick={() => setCurrentPage(number)}>{number}</button>
    </li>
  ));


  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(staffAttendanceData.length / attendancePerPage)));
  };

  return (
    <div className="checking-attendance-staff-view-container">
      <h2 className="checking-attendance-staff-view-heading">Staff Attendance View</h2>
      <div className="checking-attendance-staff-filters">
        <label htmlFor="checkingDate" className="checking-attendance-label">
          Select Date:
        </label>
        <input
          type="date"
          id="checkingDate"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="checking-attendance-input"
          required
        />

        <label htmlFor="checkingDepartmentFilter" className="checking-attendance-label">
          Filter by Department:
        </label>
        <select
          id="checkingDepartmentFilter"
          value={selectedDepartment}
          onChange={(e) => setSelectedDepartment(e.target.value)}
          className="checking-attendance-select"
        >
          {departments.map((department) => (
            <option key={department} value={department}>
              {department}
            </option>
          ))}
        </select>

        <label htmlFor="checkingJobTitleFilter" className="checking-attendance-label">
          Filter by Job Title:
        </label>
        <select
          id="checkingJobTitleFilter"
          value={selectedJobTitle}
          onChange={(e) => setSelectedJobTitle(e.target.value)}
          className="checking-attendance-select"
        >
          {jobTitles.map((jobTitle) => (
            <option key={jobTitle} value={jobTitle}>
              {jobTitle}
            </option>
          ))}
        </select>
      </div>

      <table className="checking-attendance-staff-view-table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Staff Name</th>
            <th>Employee ID</th>
            <th>Date</th>
            <th>Status</th>
            <th>Department</th>
            <th>Job Title</th>
          </tr>
        </thead>
        <tbody>{renderStaffAttendance}</tbody>
      </table>

      {/* Pagination */}
      <ul className="checking-attendance-pagination">
        <li>
          <button
            onClick={() => setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage))}
            className="checking-attendance-pagination-button"
          >
            Previous
          </button>
        </li>
        <li>
          <button className="checking-attendance-pagination-button">{currentPage}</button>
        </li>
        <li>
          <button
            onClick={() =>
              setCurrentPage((prevPage) =>
                prevPage < Math.ceil(staffAttendanceData.length / attendancePerPage) ? prevPage + 1 : prevPage
              )
            }
            className="checking-attendance-pagination-button"
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  );
};

export default StaffAttendanceView;
