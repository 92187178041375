import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './css/StudentData.css';
import useAuth from './useAuth';
import apiUrl from './apiConfig';

const StudentData = () => {
  const { checkTokenExpiration } = useAuth();


  const [students, setStudents] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [batches, setBatches] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('All');
  const [selectedBatch, setSelectedBatch] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const studentsPerPage = 70;

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/studentdata`);
      setStudents(response.data);
      extractFilters(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const extractFilters = (data) => {
    const uniqueDepartments = ['All', ...new Set(data.map((student) => student.department))];
    const uniqueBatches = ['All', ...new Set(data.map((student) => student.batch))];
    setDepartments(uniqueDepartments);
    setBatches(uniqueBatches);
    
  };

  const filterStudents = () => {
    let filteredStudents = [...students];

    if (selectedDepartment !== 'All') {
      filteredStudents = filteredStudents.filter((student) => student.department === selectedDepartment);
    }

    if (selectedBatch !== 'All') {
      filteredStudents = filteredStudents.filter((student) => student.batch === selectedBatch);
    }

    filteredStudents = filteredStudents.filter((student) =>
      Object.values(student).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    filteredStudents.sort((a, b) => a.register_number.localeCompare(b.register_number, undefined, { numeric: true }));

    return filteredStudents;
  };

  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = filterStudents().slice(indexOfFirstStudent, indexOfLastStudent);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
    setCurrentPage(1); // Reset to the first page when filter changes
  };

  const handleBatchChange = (e) => {
    setSelectedBatch(e.target.value);
    setCurrentPage(1); // Reset to the first page when filter changes
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page when filter changes
  };

 


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="student-data-container">
      <h2 className="data-heading">Student Data</h2>
      <div className="filter-section">
        <label className="filter-label">
          Department:
          <select className="filter-select" value={selectedDepartment} onChange={handleDepartmentChange}>
            {departments.map((department, index) => (
              <option key={index} value={department}>
                {department}
              </option>
            ))}
          </select>
        </label>
        <label className="filter-label">
          Batch:
          <select className="filter-select" value={selectedBatch} onChange={handleBatchChange}>
            {batches.map((batch, index) => (
              <option key={index} value={batch}>
                {batch}
              </option>
            ))}
          </select>
        </label>
        
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
          className="filter-input"
        />
        <Link to="/studentinfo">
          <button className="details-button">Personal Details</button>
        </Link>
      </div>
      <table className="student-table">
        <thead>
          <tr>
            <th className="table-header">No.</th>
            <th className="table-header">Student Name</th>
            <th className="table-header">Register Number</th>
            <th className="table-header">Department</th>
            <th className="table-header">Batch</th>
          </tr>
        </thead>
        <tbody>
          {currentStudents.map((student, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{student.name}</td>
              <td>{student.register_number}</td>
              <td>{student.department}</td>
              <td>{student.batch}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {filterStudents().length > currentPage * studentsPerPage && (
          <button className="next-button" onClick={nextPage}>Next</button>
        )}
      </div>
    </div>
  );
};

export default StudentData;
