import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/MarkUpdate.css';
import useAuth from './useAuth';

import apiUrl from './apiConfig';

const MarkUpdate = () => {
  const { checkTokenExpiration } = useAuth();
  useEffect(() => {
    // Use checkTokenExpiration here or remove the declaration if not needed.
  }, [checkTokenExpiration]);

  const [registerNumber, setRegisterNumber] = useState('');
  const [marksData, setMarksData] = useState([]);
  const [editedMarks, setEditedMarks] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  // Fetch marks data for a specific register number
  const fetchMarksData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/marks/${registerNumber}`);
      setMarksData(response.data);
      setEditedMarks(response.data); // Set editedMarks with fetched data initially
    } catch (error) {
      console.error('Error fetching marks data:', error);
    }
  };

  // Update or save edited marks data
  const updateMarksData = async () => {
    try {
      // Perform an update request to your API with the edited data
      await axios.put(`${apiUrl}/marks/${registerNumber}`, editedMarks);
      setIsEditing(false); // Set isEditing to false after a successful update
      fetchMarksData(); // Fetch updated marks data after the update
    } catch (error) {
      console.error('Error updating marks data:', error);
    }
  };

  // Delete a specific mark record
  const deleteMark = async (id) => {
    try {
      await axios.delete(`${apiUrl}/marks/${id}`);
      fetchMarksData(); // Fetch updated marks data after deletion
    } catch (error) {
      console.error('Error deleting mark:', error);
    }
  };

  useEffect(() => {
    if (registerNumber) {
      fetchMarksData();
    }
  }, [registerNumber]);

  const handleEdit = (index, field, value) => {
    const updatedMarks = [...editedMarks];
    updatedMarks[index][field] = value;
    setEditedMarks(updatedMarks);
  };

  return (
    <div className='update-mark-update-container'>
      <h2 className="update-form-title">Mark Update</h2>
      <div className='input-width'>
        <input
          type="text"
          placeholder="Enter Register Number"
          value={registerNumber}
          onChange={(e) => setRegisterNumber(e.target.value)}
          className="update-input-register-number"
        />
      </div>
      <button onClick={fetchMarksData} className="update-fetch-marks-button">
        Show Marks
      </button>

      <table className="update-data-table">
        <thead>
          <tr>
            <th>Exam</th>
            <th>Subject</th>
            <th>Grade</th>
            <th>Marks</th>
            <th>Total Marks</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {marksData.map((item, index) => (
            <tr key={index}>
              <td>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedMarks[index].exam}
                    onChange={(e) => handleEdit(index, 'exam', e.target.value)}
                    className="update-edit-input-exam"
                  />
                ) : (
                  item.exam
                )}
              </td>
              <td>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedMarks[index].subject}
                    onChange={(e) => handleEdit(index, 'subject', e.target.value)}
                    className="update-edit-input-subject"
                  />
                ) : (
                  item.subject
                )}
              </td>
              <td>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedMarks[index].grade}
                    onChange={(e) => handleEdit(index, 'grade', e.target.value)}
                    className="update-edit-input-grade"
                  />
                ) : (
                  item.grade
                )}
              </td>
              <td>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedMarks[index].marks}
                    onChange={(e) => handleEdit(index, 'marks', e.target.value)}
                    className="update-edit-input-marks"
                  />
                ) : (
                  item.marks
                )}
              </td>
              <td>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedMarks[index].total_marks}
                    onChange={(e) => handleEdit(index, 'total_marks', e.target.value)}
                    className="update-edit-input-total-marks"
                  />
                ) : (
                  item.total_marks
                )}
              </td>
              <td>
                {isEditing ? (
                  <button className='update-save-button' onClick={() => updateMarksData()}>
                    Save
                  </button>
                ) : (
                  <>
                    <button onClick={() => setIsEditing(true)} className="update-edit-button">
                      Edit
                    </button>
                    <button onClick={() => deleteMark(item.id)} className="update-delete-button">
                      Delete
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MarkUpdate;
