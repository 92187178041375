// NavigationBar.js
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './css/NavigationBar.css';

import erpLogo from './assets/logo.png';

const NavigationBar = () => {
  const [showSecurityDropdown, setShowSecurityDropdown] = useState(false);
  const [showStudentsDropdown, setShowStudentsDropdown] = useState(false);
  const [showStaffDropdown, setShowStaffDropdown] = useState(false);
  const [showAdminDropdown, setShowAdminDropdown] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const history = useHistory();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > 768) {
        setShowMenu(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogout = async () => {
    try {
      localStorage.removeItem('token');
      localStorage.removeItem('iat');
      localStorage.removeItem('access_token');
      history.push('/api/admin/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const closeMobileMenu = () => {
    setShowMenu(false);
  };

  const toggleSecurityDropdown = () => {
    setShowSecurityDropdown(!showSecurityDropdown);
    setShowStudentsDropdown(false);
    setShowStaffDropdown(false);
    setShowAdminDropdown(false);
  // Close mobile menu on submenu click
  };

  const toggleStudentsDropdown = () => {
    setShowSecurityDropdown(false);
    setShowStudentsDropdown(!showStudentsDropdown);
    setShowStaffDropdown(false);
    setShowAdminDropdown(false);
 // Close mobile menu on submenu click
  };

  const toggleStaffDropdown = () => {
    setShowSecurityDropdown(false);
    setShowStudentsDropdown(false);
    setShowStaffDropdown(!showStaffDropdown);
    setShowAdminDropdown(false);
 // Close mobile menu on submenu click
  };

  const toggleAdminDropdown = () => {
    setShowSecurityDropdown(false);
    setShowStudentsDropdown(false);
    setShowStaffDropdown(false);
    setShowAdminDropdown(!showAdminDropdown);
 // Close mobile menu on submenu click
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav>
      <div className="logo-container">
        <img src={erpLogo} alt="ERP Logo" className="erp-logo" />
        <h1 className="header">ERP</h1>
      </div>
      <div className="mobile-nav">
        <div className={`menu-icon ${showMenu ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        {showMenu && (
          <ul className="dropdown-content-mobile">
            <li>
              <button className="dropbtn-mobile" onClick={toggleSecurityDropdown}>
                Security
              </button>
              {showSecurityDropdown && (
                <div className="dropdown-submenu">
                  <Link to="/security" onClick={closeMobileMenu}>Dashboard</Link>
                  <Link to="/userview" onClick={closeMobileMenu}>User View</Link>
                </div>
              )}
            </li>
            <li>
              <button className="dropbtn-mobile" onClick={toggleStudentsDropdown}>
                Students
              </button>
              {showStudentsDropdown && (
                <div className="dropdown-submenu">
                  <Link to="/studentform" onClick={closeMobileMenu}>Student Form</Link>
                  <Link to="/studentdata" onClick={closeMobileMenu}>Student Data</Link>
                  <Link to="/studentinfo" onClick={closeMobileMenu}>Student Info</Link>
                  <Link to="/studentmarks" onClick={closeMobileMenu}>Internals</Link>
                  <Link to="/attendance" onClick={closeMobileMenu}>Attendance</Link>
                  <Link to="/attendanceview" onClick={closeMobileMenu}>Report</Link>
                  
                  {/* Add more Student links if needed */}
                </div>
              )}
            </li>
            <li>
              <button className="dropbtn-mobile" onClick={toggleStaffDropdown}>
                Teacher
              </button>
              {showStaffDropdown && (
                <div className="dropdown-submenu">
                  <Link to="/staffform" onClick={closeMobileMenu}>Staff Form</Link>
                  <Link to="/staffdata" onClick={closeMobileMenu}>Staff Data</Link>
                  <Link to="/staffinfo" onClick={closeMobileMenu}>Staff Info</Link>
                  <Link to="/markupdate" onClick={closeMobileMenu}>Mark Update</Link>
                  <Link to="/staffattendance" onClick={closeMobileMenu}>Attendance</Link>
                  <Link to="/staffattendanceview" onClick={closeMobileMenu}>Report</Link>
                  <Link to="/uploadcircular" onClick={closeMobileMenu}>Circular</Link>
                  <Link to="/updatecircular" onClick={closeMobileMenu}>Update Circular</Link>
                  <Link to="/homework" onClick={closeMobileMenu}>HomeWork</Link>
                  <Link to="/homeupdate" onClick={closeMobileMenu}>Update HomeWork</Link>

                </div>
              )}
            </li>
            <li>
              <button className="dropbtn-mobile" onClick={toggleAdminDropdown}>
                Admin
              </button>
              {showAdminDropdown && (
                <div className="dropdown-submenu">
                  <Link to="/api/admin/login" onClick={closeMobileMenu}>Admin Login</Link>
                  <Link to="/api/admin/signup" onClick={closeMobileMenu}>Admin Signup</Link>
                  <Link to="/studentlogin" onClick={closeMobileMenu}>Students Login</Link>
                  <Link to="/Circular" onClick={closeMobileMenu}>Circular</Link>
                  <Link to="/studentwork" onClick={closeMobileMenu}>HomeWork</Link>
                  
                  <a onClick={() => { handleLogout(); closeMobileMenu(); }}>Logout</a>
                </div>
              )}
            </li>
          </ul>
        )}
      </div>
      <ul className={`navbar ${windowWidth > 768 ? 'active' : ''}`}>
        <li className="navbar-item">
          <div className="dropdown">
            <button className="dropbtn" onClick={toggleSecurityDropdown}>
              Security
            </button>
            {showSecurityDropdown && (
              <div className="dropdown-content">
                <Link to="/security" onClick={closeMobileMenu}>Dashboard</Link>
                <Link to="/userview" onClick={closeMobileMenu}>User View</Link>
              </div>
            )}
          </div>
        </li>
        <li className="navbar-item">
          <div className="dropdown">
            <button className="dropbtn" onClick={toggleStudentsDropdown}>
              Students
            </button>
            {showStudentsDropdown && (
              <div className="dropdown-content">
                <Link to="/studentform" onClick={closeMobileMenu}>Student Form</Link>
                <Link to="/studentdata" onClick={closeMobileMenu}>Student Data</Link>
                <Link to="/studentinfo" onClick={closeMobileMenu}>Student Info</Link>
                <Link to="/studentmarks" onClick={closeMobileMenu}>Student Marks</Link>
                <Link to="/attendance" onClick={closeMobileMenu}>Attendance</Link>
                <Link to="/attendanceview" onClick={closeMobileMenu}>Report</Link>
              
                {/* Add more Student links if needed */}
              </div>
            )}
          </div>
        </li>
        <li className="navbar-item">
          <div className="dropdown">
            <button className="dropbtn" onClick={toggleStaffDropdown}>
              Teacher
            </button>
            {showStaffDropdown && (
              <div className="dropdown-content">
                <Link to="/staffform" onClick={closeMobileMenu}>Staff Form</Link>
                <Link to="/staffdata" onClick={closeMobileMenu}>Staff Data</Link>
                <Link to="/staffinfo" onClick={closeMobileMenu}>Staff Info</Link>
                <Link to="/markupdate" onClick={closeMobileMenu}>Mark Update</Link>
                <Link to="/staffattendance" onClick={closeMobileMenu}>Attendance</Link>
                <Link to="/staffattendanceview" onClick={closeMobileMenu}>Report</Link>
                <Link to="/uploadcircular" onClick={closeMobileMenu}>Circular</Link>
                <Link to="/updatecircular" onClick={closeMobileMenu}>Update Circular</Link>
                <Link to="/homework" onClick={closeMobileMenu}>HomeWork</Link>
                <Link to="/homeupdate" onClick={closeMobileMenu}>Update HomeWork</Link>
                  
              </div>
            )}
          </div>
        </li>
        <li className="navbar-item">
          <div className="dropdown">
            <button className="dropbtn" onClick={toggleAdminDropdown}>
              Account
            </button>
            {showAdminDropdown && (
              <div className="dropdown-content">
                <Link to="/studentlogin" onClick={closeMobileMenu}>Students Login</Link>
                <Link to="/api/admin/login" onClick={closeMobileMenu}>Admin Login</Link>
                <Link to="/api/admin/signup" onClick={closeMobileMenu}>Admin Signup</Link>
                <Link to="/Circular" onClick={closeMobileMenu}>Circular</Link>
                <Link to="/studentwork" onClick={closeMobileMenu}>HomeWork</Link>


                <a className='cursor' onClick={() => { handleLogout(); closeMobileMenu(); }}>Logout</a>
              </div>
            )}
          </div>
        </li>
        {/* Add more main elements with dropdowns if needed */}
      </ul>
    </nav>
  );
};

export default NavigationBar;
