import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './css/staffdata.css';  // Importing the same CSS file used by StudentData
import useAuth from './useAuth';
import apiUrl from './apiConfig';

const StaffsData = () => {

  const { checkTokenExpiration } = useAuth();


  const [staffs, setStaffs] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [batches, setBatches] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('All');
  const [selectedBatch, setSelectedBatch] = useState('All');
  const [selectedJobTitle, setSelectedJobTitle] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  
  const staffsPerPage = 70; // You can adjust the number of staffs per page as needed

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/staffsdata`);
      setStaffs(response.data);
      extractFilters(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const extractFilters = (data) => {
    const uniqueDepartments = ['All', ...new Set(data.map((staff) => staff.department))];
    const uniqueBatches = ['All', ...new Set(data.map((staff) => staff.batch))];
    setDepartments(uniqueDepartments);
    setBatches(uniqueBatches);
  };


  const filterStaffs = () => {
    let filteredStaffs = [...staffs];

    if (selectedDepartment !== 'All') {
      filteredStaffs = filteredStaffs.filter((staff) => staff.department === selectedDepartment);
    }

    if (selectedBatch !== 'All') {
      filteredStaffs = filteredStaffs.filter((staff) => staff.batch === selectedBatch);
    }

    if (selectedJobTitle !== 'All') {
      filteredStaffs = filteredStaffs.filter((staff) => staff.job_title.toLowerCase() === selectedJobTitle.toLowerCase());
    }

    // Filter based on search term
    filteredStaffs = filteredStaffs.filter((staff) =>
      Object.values(staff).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    // Sorting logic (assuming sorting by employee ID)
    filteredStaffs.sort((a, b) => {
      return a.employee_id.localeCompare(b.employee_id, undefined, { numeric: true });
    });

    return filteredStaffs;
  };

  const indexOfLastStaff = currentPage * staffsPerPage;
  const indexOfFirstStaff = indexOfLastStaff - staffsPerPage;
  const currentStaffs = filterStaffs().slice(indexOfFirstStaff, indexOfLastStaff);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
    setCurrentPage(1); // Reset to the first page when filter changes
  };

  const handleBatchChange = (e) => {
    setSelectedBatch(e.target.value);
    setCurrentPage(1); // Reset to the first page when filter changes
  };

  const handleJobTitleChange = (e) => {
    setSelectedJobTitle(e.target.value);
    setCurrentPage(1); // Reset to the first page when filter changes
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page when filter changes
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="student-data-container">
      <h2 className="data-heading">Staffs Data</h2>
      <div className="filter-section">
        <label className="filter-label">
          Department:
          <select className="filter-select" value={selectedDepartment} onChange={handleDepartmentChange}>
            {departments.map((department, index) => (
              <option key={index} value={department}>
                {department}
              </option>
            ))}
          </select>
        </label>
        <label className="filter-label">
          Batch:
          <select className="filter-select" value={selectedBatch} onChange={handleBatchChange}>
            {batches.map((batch, index) => (
              <option key={index} value={batch}>
                {batch}
              </option>
            ))}
          </select>
        </label>
        <label className="filter-label">
          Job Title:
          <select className="filter-select" value={selectedJobTitle} onChange={handleJobTitleChange}>
            <option value="All">All</option>
            <option value="Teaching">Teaching</option>
            <option value="Non-Teaching">Non-Teaching</option>
          </select>
        </label>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
          className="filter-input"
        />
        <Link to="/StaffInfo">
          <button className="details-button">Personal Details</button>
        </Link>
      </div>
      <table className="student-table">
        <thead>
          <tr>
            <th className="table-header">No.</th>
            <th className="table-header">Staff Name</th>
            <th className="table-header">Employee ID</th>
            <th className="table-header">Department</th>
            <th className="table-header">Batch</th>
            <th className="table-header">Job Title</th>
          </tr>
        </thead>
        <tbody>
          {currentStaffs.map((staff, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{staff.staff_name}</td>
              <td>{staff.employee_id}</td>
              <td>{staff.department}</td>
              <td>{staff.batch}</td>
              <td>{staff.job_title}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {filterStaffs().length > currentPage * staffsPerPage && (
          <button className="next-button" onClick={nextPage}>Next</button>
        )}
      </div>
    </div>
  );
};

export default StaffsData;
