// ResultView.js

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './css/ResultView.css';
import apiUrl from './apiConfig';

const ResultView = () => {

  const [userDetails, setUserDetails] = useState({});
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${apiUrl}/api/student/marks`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (response.ok) {
          setUserDetails({
            registerNumber: data.registerNumber,
            name: data.name,
            department: data.department,
            dob: data.dob,
          });
          setResults(data.results);
        } else {
          if (response.status === 401) {
            // Token expired, log out and redirect to student login
            localStorage.removeItem('access_token');
            history.push('/studentlogin');
          } else {
            setError(data.message || 'Failed to fetch results.');
          }
        }
      } catch (error) {
        console.error('Error fetching results:', error);
        setError('An unexpected error occurred.');
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [history]);

  const handleLogout = () => {
    // Clear the access token and redirect to student login
    localStorage.removeItem('access_token');
    history.push('/studentlogin');
  };

  return (
    <div className="ResultView-container">
      <h2>Result View</h2>

      {/* User Details */}
      <div className="ResultView-user-details">
        <p>
          <strong>Student Name:</strong> {userDetails.name}
        </p>
        <p>
          <strong>Register Number:</strong> {userDetails.registerNumber}
        </p>
        <p>
          <strong>Department:</strong> {userDetails.department}
        </p>
        <p>
          <strong>Date of Birth:</strong> {userDetails.dob}
        </p>
      </div>


      {/* Results */}
      {loading && <p className="ResultView-loading">Loading...</p>}
      {!loading && results.length > 0 ? (
        <div className="ResultView-results">
          {results.map((result, index) => (
            <div key={index} className="ResultView-exam">
              <h3>Exam Name: {result.exam}</h3>
              <h4>Total Marks: {result.totalMarks}</h4> 
              <table className="ResultView-results-table">
                <thead>
                  <tr>
                    <th>Grade</th>
                    <th>Subject</th>
                    <th>Marks</th>
                    <th>Result</th>
                  </tr>
                </thead>
                <tbody>
                  {result.marks.map((mark, index) => (
                    <tr key={index}>
                      <td>{mark.grade}</td>
                      <td>{mark.subject}</td>
                      <td>{mark.marks}</td>
                      <td>{mark.result}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          ))}
          <button className="ResultView-logout-button" onClick={handleLogout}>
        Logout
      </button>
        </div>
      ) : (
        <p className="ResultView-no-results">
          {!loading ? 'No results available.' : null}
        </p>
      )}

      {error && <div className="ResultView-error" style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};

export default ResultView;
