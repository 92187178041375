import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/StudentInfo.css'; // Import CSS file for styling
import useAuth from './useAuth';
import apiUrl from './apiConfig';

const StudentInfo = () => {
  const { checkTokenExpiration } = useAuth();

  const [registerNumber, setRegisterNumber] = useState('');
  const [studentDetails, setStudentDetails] = useState({});
  const [updatedDetails, setUpdatedDetails] = useState({});
  const [error, setError] = useState('');
  const [showUpdateFields, setShowUpdateFields] = useState(false); // State to toggle update fields visibility
  const [newProfilePic, setNewProfilePic] = useState(null); // State for new profile picture

  useEffect(() => {
    setUpdatedDetails(studentDetails); // Update updatedDetails when studentDetails changes
  }, [studentDetails]);

  const fieldOrder = [
    'name',
    'department',
    'batch',
    'dob',
    'gender',
    'register_number',
    'id_number',
    'religion',
    'community',
    'student_phone',
    'email',
    'guardian_name',
    'address',
    'state',
    'country',
    'pin_code',
    'boarding_status',
    'admission_quota',
  ];

  const fetchStudentDetails = async () => {
    try {
      const response = await axios.get(`${apiUrl}/studentdata/${registerNumber}`);
      setStudentDetails(response.data);
      setError('');
    } catch (error) {
      console.error('Error fetching student details:', error);
      setError('Invalid Register Number!');
      window.alert('Invalid Register Number!');
    }
  };

  const updateStudentDetails = async () => {
    try {
      const response = await axios.put(`${apiUrl}/studentdata/${registerNumber}`, updatedDetails);
      console.log(response.data); // Log the response or handle it accordingly
      fetchStudentDetails(); // Fetch updated details after the update
      setShowUpdateFields(false); // Hide update fields after successful update
      window.alert('Student Details Updated')
      window.location.reload()
    } catch (error) {
      console.error('Error updating student details:', error);
    }
  };

  const changeProfilePic = async () => {
    try {
      const formData = new FormData();
      formData.append('newProfilePic', newProfilePic);

      const response = await axios.put(`${apiUrl}/studentpic/${registerNumber}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response.data);
      fetchStudentDetails();
      window.alert('Student photo Updated')
      window.location.reload()
    } catch (error) {
      console.error('Error changing profile picture:', error);
    }
  };

  const deleteStudent = async () => {
    try {
      await axios.delete(`${apiUrl}/studentdata/${registerNumber}`);
      // Assuming the server returns a success response, you may want to handle it accordingly.
      window.alert('Student deleted successfully!');
      setStudentDetails({}); // Clear student details after deletion
    } catch (error) {
      console.error('Error deleting student:', error);
      window.alert('Error deleting student. Please try again.');
    }
  };

  const handleFileChange = (e) => {
    setNewProfilePic(e.target.files[0]);
  };

  const handleFieldChange = (field, value) => {
    setUpdatedDetails({ ...updatedDetails, [field]: value });
  };

  const renderTable = () => {
    return (
      <table className="info-details-table">
        <tbody>
          {fieldOrder.map((key) => (
            <tr key={key}>
              <td className="info-field-label">{formatHeader(key)}:</td>
              <td className="info-field-input">
                {showUpdateFields ? (
                  <input
                    className="info-update-text"
                    type="text"
                    placeholder={`Enter updated ${key.toLowerCase()}`}
                    value={updatedDetails[key] || ''}
                    onChange={(e) => handleFieldChange(key, e.target.value)}
                  />
                ) : (
                  <span>{studentDetails[key]}</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  
  // Function to format headers by replacing underscores with spaces and capitalizing words
  const formatHeader = (header) => {
    return header
      .replace(/_/g, ' ') // Replace underscores with spaces
      .split(' ') // Split into words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join words with spaces
  };

  return (
    <div className="info-student-info-container">
      <div className='info-title3'>
        <h2>Student Information</h2>
      </div>
      <div className="info-center">
        <div className="info-search-section">
          <input
            type="text"
            className="info-register"
            placeholder="Enter Register Number"
            value={registerNumber}
            onChange={(e) => setRegisterNumber(e.target.value)}
          />
          <button className="info-details" onClick={fetchStudentDetails}>
            Details
          </button>
        </div>
      </div>

      <div className="info-student-details">
        {Object.keys(studentDetails).length > 0 && (
          <div>
            <div className="info-student-photo-container">
              <img
                className="info-student-photo"
                src={`${apiUrl}/studentpic/${studentDetails.photo_link}`}
                alt="Student"
              />
            </div>
            {renderTable()}
            <p className="info-error-message">{error}</p>
            {!showUpdateFields ? (
              <div>
                <button className="info-change" onClick={() => setShowUpdateFields(true)}>
                  Change
                </button>
                <button className="info-delete" onClick={deleteStudent}>
                  Delete
                </button>
              </div>
            ) : (
              <>
                <button className="info-change" style={{ width: '50%', marginTop: '20px' }} onClick={updateStudentDetails}>
                  Update
                </button>
                <div className="info-change-profile-pic">
                  <input className="info-file" type="file" accept="image/*" onChange={handleFileChange} />
                  <button className="info-change" style={{ width: '50%', marginTop: '20px' }} onClick={changeProfilePic}>
                    Change Profile Pic
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentInfo;
