import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiUrl from './apiConfig';
import './css/studentwork.css';

const StudentWork = () => {
  const [departments, setDepartments] = useState([]);
  const [batches, setBatches] = useState([]);
  const [filteredHomeworks, setFilteredHomeworks] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedBatch, setSelectedBatch] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch departments and batches on component mount
    fetchDepartments();
    fetchBatches();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await axios.get(`${apiUrl}/departments`);
      setDepartments(response.data);
    } catch (error) {
      console.error('Error fetching departments:', error);
      setError('Failed to fetch departments');
    }
  };

  const fetchBatches = async () => {
    try {
      const response = await axios.get(`${apiUrl}/batches`);
      setBatches(response.data);
    } catch (error) {
      console.error('Error fetching batches:', error);
      setError('Failed to fetch batches');
    }
  };

  const handleFilterSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.get(`${apiUrl}/homework`, {
        params: {
          department: selectedDepartment,
          batch: selectedBatch,
          date_assigned: selectedDate
        }
      });
      
      setFilteredHomeworks(response.data);
    } catch (error) {
      console.error('Error fetching filtered homework:', error);
      setError('Failed to fetch filtered homework');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="studentwork-container">
      <h2>Student Homework</h2>
      <form onSubmit={handleFilterSubmit}>
        <div className="studentwork-form-group">
          <label htmlFor="department">Department:</label>
          <select
            id="department"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
            required
          >
            <option value="">Select Department</option>
            {departments.map((dept) => (
              <option key={dept} value={dept}>{dept}</option>
            ))}
          </select>
        </div>
        <div className="studentwork-form-group">
          <label htmlFor="batch">Batch:</label>
          <select
            id="batch"
            value={selectedBatch}
            onChange={(e) => setSelectedBatch(e.target.value)}
            required
          >
            <option value="">Select Batch</option>
            {batches.map((batch) => (
              <option key={batch} value={batch}>{batch}</option>
            ))}
          </select>
        </div>
        <div className="studentwork-form-group">
          <label htmlFor="dateAssigned">Date Assigned:</label>
          <input
            type="date"
            id="dateAssigned"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          />
        </div>
        <button type="submit" disabled={loading}>Get HomeWork</button>
      </form>
      {error && <p>{error}</p>}
      {loading && <p>Loading...</p>}
      {filteredHomeworks.length > 0 ? (
        <div className="studentwork-homework-list">
          <h3>Homework Assignments</h3>
          <ul>
            {filteredHomeworks.map((hw) => (
              <li key={hw.id} className="studentwork-homework-item">
                <strong>Date Assigned:</strong> {hw.date_assigned}<br />
                <strong>Department:</strong> {hw.department}<br />
                <strong>Batch:</strong> {hw.batch}<br />
                <br />
                <strong>Subject:</strong> {hw.subject_name}<br />
                <strong>Details:</strong> {hw.homework_details}<br />
                
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>No homework assignments found.</p>
      )}
    </div>
  );
};

export default StudentWork;
