// useAuth.js

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useAuth = () => {
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');

    // Check if the token is expired or tampered
    const { isExpired, isTampered } = checkToken(token);

    if (!token || isExpired || isTampered) {
      localStorage.removeItem('token');
      localStorage.removeItem('iat');
      history.push('/api/admin/login');
    }
  }, [history]);

  const checkToken = (token) => {
    if (!token) return { isExpired: true, isTampered: true };

    try {
      const decodedToken = parseJwt(token);
      const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

      // Check if the token is expired
      const isExpired = decodedToken.exp < currentTime;

      // Additional check: Compare the stored 'iat' (issued at) with decoded 'iat'
      const storedIat = localStorage.getItem('iat');
      const isTampered = storedIat && storedIat !== decodedToken.iat.toString();

      return { isExpired, isTampered };
    } catch (error) {
      return { isExpired: true, isTampered: true }; // Handle token decoding errors
    }
  };

  const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64));
    return JSON.parse(jsonPayload);
  };

  return { checkToken };
};

export default useAuth;
