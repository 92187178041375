import React, { useState } from 'react';
import axios from 'axios';
import './css/stafform.css'
import useAuth from './useAuth';
import apiUrl from './apiConfig';

const StaffsForm = () => {
  const { checkTokenExpiration } = useAuth();



  const [formData, setFormData] = useState({
    staff_name: '',
    department: '',
    batch: '',
    dob: '',
    gender: '',
    job_title: '',
    employee_id: '',
    employee_status: '',
    employment_status: '',
    educational_qualification: '',
    religion: '',
    community: '',
    staff_phone: '',
    email: '',
    spouse_name  : '',
    address: '',
    state: '',
    country: '',
    pin_code: '',
    boarding_status: '',
    date_of_joining:'' 

  });

  

  const [photo, setPhoto] = useState(null);
  const [extraFields, setExtraFields] = useState([]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };



  const handleFileChange = (e) => {
    setPhoto(e.target.files[0]);
  };


  const handleAddField = () => {
    setExtraFields([...extraFields, { name: '', value: '' }]);
  };

  const handleExtraFieldNameChange = (index, value) => {
    const updatedFields = [...extraFields];
    updatedFields[index].name = value;
    setExtraFields(updatedFields);
  };


  const handleExtraFieldValueChange = (index, value) => {
    const updatedFields = [...extraFields];
    updatedFields[index].value = value;
    setExtraFields(updatedFields);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const allFields = new FormData();

        allFields.append('staff_name', formData.staff_name);
        allFields.append('department', formData.department);
        allFields.append('batch', formData.batch);
        allFields.append('dob', formData.dob);
        allFields.append('gender', formData.gender);
        allFields.append('job_title', formData.job_title);
        allFields.append('employee_id', formData.employee_id);
        allFields.append('employee_status', formData.employee_status);
        allFields.append('employment_status', formData.employment_status);
        allFields.append('educational_qualification', formData.educational_qualification);
        allFields.append('religion', formData.religion);
        allFields.append('community', formData.community);
        allFields.append('staff_phone', formData.staff_phone);
        allFields.append('email', formData.email);
        allFields.append('spouse_name', formData.spouse_name);
        allFields.append('address', formData.address);
        allFields.append('state', formData.state);
        allFields.append('country', formData.country);
        allFields.append('pin_code', formData.pin_code);
        allFields.append('boarding_status', formData.boarding_status);
        allFields.append('date_of_joining', formData.date_of_joining);

        if (photo) {
            allFields.append('photo', photo);
          }
          extraFields.forEach((field, index) => {
            allFields.append(`field_${index + 1}_${field.name}`, field.value);
          });

      const response = await axios.post(`${apiUrl}/staff`, allFields);
      console.log(response.data); // Log response from Flask backend
      // Optionally, reset form fields after successful submission
      setFormData({
    staff_name: '',
    department: '',
    batch: '',
    dob: '',
    gender: '',
    job_title: '',
    employee_id: '',
    employee_status: '',
    employment_status: '',
    educational_qualification: '',
    religion: '',
    community: '',
    staff_phone: '',
    email: '',
    spouse_name  : '',
    address: '',
    state: '',
    country: '',
    pin_code: '',
    boarding_status: '',
    date_of_joining:'' 

        // Reset other fields as needed
      });
      setPhoto(null);
      setExtraFields([]);
    } catch (error) {
      console.error('Error adding student:', error);
    }
  };

  return (
    <div id='container'>
    <div className="form-container">
      <h2 className="form-title">Staff Information Form</h2>
      <form onSubmit={handleSubmit} className="form">
        <label>
          Name:
          <input type="text" name="staff_name" value={formData.name} onChange={handleChange} required />
        </label>
        <label>
          Department:
          <input type="text" name="department" value={formData.department} onChange={handleChange} required/>
        </label>
        {/* Add other input fields for student information */}

        <label>
        Batch:
          <input type="text" name="batch" value={formData.batch} onChange={handleChange} required/>
        </label>

        <label>
          Date of birth : 
          <input type="text" name="dob" value={formData.dob} onChange={handleChange} required/>
        </label>


        <label>
          Gender :
          <input type="text" name="gender" value={formData.gender} onChange={handleChange} required />
        </label>

        
        <label>
          Role:
          <select name="job_title" value={formData.job_title} onChange={handleChange} required>
            <option value="">Select Role</option>
            <option value="teaching">Teaching Staff</option>
            <option value="non-teaching">Non-Teaching Staff</option>
          </select>
        </label>


        <label>
        ID Number :
          <input type="text" name="employee_id" value={formData.employee_id} onChange={handleChange} required />
        </label>


        

        
        <label>
          Employment Status  :
          <input type="text" name="employment_status" value={formData.employment_status} onChange={handleChange} required />
        </label>

        <label>
           Educational Qualification:
          <input type="text" name="educational_qualification" value={formData.educational_qualification} onChange={handleChange} required />
        </label>


        <label>
         Religion :
          <input type="text" name="religion" value={formData.religion} onChange={handleChange} required />
        </label>

        <label>
        Community:
          <input type="text" name="community" value={formData.community} onChange={handleChange} required />
        </label>

        <label>
         Staff Phone:
          <input type="text" name="staff_phone" value={formData.staff_phone} onChange={handleChange} required />
        </label>

        <label>
          Email:
          <input type="text" name="email" value={formData.email} onChange={handleChange} required />
        </label>

        <label>
        Spouse Name:
          <input type="text" name="spouse_name" value={formData.spouse_name} onChange={handleChange} required />
        </label>
        <label>
        Address:
          <input type="text" name="address" value={formData.address} onChange={handleChange} required />
        </label>

        <label>
        State:
          <input type="text" name="state" value={formData.state} onChange={handleChange} required />
        </label>


        <label>
          Country:
          <input type="text" name="country" value={formData.country} onChange={handleChange} required />
        </label>


        <label>
        Pincode:
          <input type="text" name="pin_code" value={formData.pin_code} onChange={handleChange} required />
        </label>

        <label>
        Boarding Status:
          <input type="text" name="boarding_status" value={formData.boarding_status} onChange={handleChange} required />
        </label>


        <label>
        Date Of Joining:
          <input type="text" name="date_of_joining" value={formData.date_of_joining} onChange={handleChange} required />
        </label>

        <label className="label-photo">
          Photo:
          <input type="file" onChange={handleFileChange} className="input-photo" required />
        </label>



        {extraFields.map((field, index) => (
          <div key={index}>
            <label>
              Field Name:
              <input
                type="text"
                value={field.name}
                onChange={(e) => handleExtraFieldNameChange(index, e.target.value)}
                required
              />
            </label>
            <label>
              Field Value:
              <input
                type="text"
                value={field.value}
                onChange={(e) => handleExtraFieldValueChange(index, e.target.value)}
                required
              />
            </label>
          </div>
        ))}
        <div className="button-container">


        <button type="submit" className="submit-button">Submit</button>
        </div>
      </form>
    </div>
    </div>
  );
};

export default StaffsForm;