import React from 'react';
import './css/Unauthorized.css'; // Import the CSS file


const Unauthorized = () => {

  return (
    <div className="Unauthorized-container">
      <h2 className="Unauthorized-heading">Unauthorized Access</h2>
      <p className="Unauthorized-message">You do not have permission to access this page.</p>
      <a className="Unauthorized-button" href='/'>Go Back</a>
    </div>
  );
};

export default Unauthorized;
