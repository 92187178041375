import React, { useState } from 'react';
import axios from 'axios';
import apiUrl from './apiConfig';
import useAuth from './useAuth';
import './css/homework.css'

const Homework = () => {

  const { checkTokenExpiration } = useAuth();

  const [subjects, setSubjects] = useState([]);
  const [homeworks, setHomeworks] = useState([]);
  const [dateAssigned, setDateAssigned] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedBatch, setSelectedBatch] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post(`${apiUrl}/homework/assign`, {
        department: selectedDepartment,
        batch: selectedBatch,
        date_assigned: dateAssigned,
        assignments: homeworks.map((hw, index) => ({
          subject: subjects[index],
          homework: hw,
        }))
      });

      if (response.status === 200) {
        alert('Homework assigned successfully!');
        // Optionally clear form fields or reset state
        setSubjects([]);
        setHomeworks([]);
        setDateAssigned('');
        setSelectedDepartment('');
        setSelectedBatch('');
      } else {
        setError('Failed to assign homework. Please try again.');
      }
    } catch (error) {
      console.error('Error assigning homework:', error);
      setError('Failed to assign homework. Please check your network connection and try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubjectChange = (index, e) => {
    const updatedSubjects = [...subjects];
    updatedSubjects[index] = e.target.value;
    setSubjects(updatedSubjects);
  };

  const handleHomeworkChange = (index, e) => {
    const updatedHomeworks = [...homeworks];
    updatedHomeworks[index] = e.target.value;
    setHomeworks(updatedHomeworks);
  };

  const handleAddAssignment = () => {
    setSubjects([...subjects, '']);
    setHomeworks([...homeworks, '']);
  };

  const handleRemoveAssignment = (index) => {
    const updatedSubjects = [...subjects];
    const updatedHomeworks = [...homeworks];
    updatedSubjects.splice(index, 1);
    updatedHomeworks.splice(index, 1);
    setSubjects(updatedSubjects);
    setHomeworks(updatedHomeworks);
  };

  return (
    <div className="homework-container">
      <h2>Assign Homework</h2>
      <form onSubmit={handleSubmit} className="homework-form">
        <div className="homework-form-group">
          <label htmlFor="homework-department">Department:</label>
          <input
            type="text"
            id="homework-department"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
            required
          />
        </div>
        <div className="homework-form-group">
          <label htmlFor="homework-batch">Batch:</label>
          <input
            type="text"
            id="homework-batch"
            value={selectedBatch}
            onChange={(e) => setSelectedBatch(e.target.value)}
            required
          />
        </div>
        <div className="homework-form-group">
          <label htmlFor="homework-dateAssigned">Date Assigned:</label>
          <input
            type="date"
            id="homework-dateAssigned"
            value={dateAssigned}
            onChange={(e) => setDateAssigned(e.target.value)}
            required
          />
        </div>
        {subjects.map((subject, index) => (
          <div key={index} className="homework-form-group">
            <label htmlFor={`homework-subject-${index}`}>Subject:</label>
            <input
              type="text"
              id={`homework-subject-${index}`}
              value={subject}
              onChange={(e) => handleSubjectChange(index, e)}
              required
            />
            <label htmlFor={`homework-details-${index}`}>Homework:</label>
            <textarea
              id={`homework-details-${index}`}
              value={homeworks[index]}
              onChange={(e) => handleHomeworkChange(index, e)}
              required
            />
            {index > 0 && (
              <button type="button" onClick={() => handleRemoveAssignment(index)} className="homework-remove-button">
                Remove
              </button>
            )}
          </div>
        ))}
        <button type="button" onClick={handleAddAssignment} className="homework-add-button">
          Add Assignment
        </button>
        <button type="submit" disabled={loading} className="homework-submit-button">
          Assign Homework
        </button>
      </form>
      {error && <p className="homework-error">{error}</p>}
      {loading && <p className="homework-loading">Loading...</p>}
    </div>
  );
};

export default Homework;
