// UserView.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/UserView.css';
import useAuth from './useAuth';
import apiUrl from './apiConfig';



const UserView = () => {
  const { checkTokenExpiration } = useAuth();

  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(2);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [editUserId, setEditUserId] = useState(null);
  const [editUsername, setEditUsername] = useState('');
  const [editUserIdInput, setEditUserIdInput] = useState('');
  const [editRole, setEditRole] = useState('');
  const [editPassword, setEditPassword] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/get_users_available`);
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = searchResults.length > 0 ? searchResults : userData.slice(indexOfFirstUser, indexOfLastUser);

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = () => {
    const filteredUsers = userData.filter((user) =>
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredUsers);
    setCurrentPage(1); // Reset to the first page after a new search
  };

  const handleEdit = (userId, username, user_id, role, password) => {
    setEditUserId(userId);
    setEditUsername(username);
    setEditUserIdInput(user_id);
    setEditRole(role);
    setEditPassword(password);
  };

  const handleUpdate = async (userId) => {
    try {
      await axios.put(`${apiUrl}/update_user/${userId}`, {
        username: editUsername,
        user_id: editUserIdInput,
        role: editRole,
        password: editPassword,
      });
      fetchData();
      setEditUserId(null);
      setEditUsername('');
      setEditUserIdInput('');
      setEditRole('');
      setEditPassword('');
      toast.success('User updated successfully');
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Error updating user');
    }
  };

  const handleDelete = async (userId) => {
    try {
      await axios.delete(`${apiUrl}/delete_user/${userId}`);
      fetchData();
      setEditUserId(null);
      setEditUsername('');
      setEditUserIdInput('');
      setEditRole('');
      setEditPassword('');
      toast.success('User deleted successfully');
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Error deleting user');
    }
  };

  const renderUsers = () => {
    return (
      <table className="userview-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Username</th>
            <th>User ID</th>
            <th>Role</th>
            <th>Password</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id} className="userview-row">
              <td>{user.id}</td>
              <td>
                {editUserId === user.id ? (
                  <input
                    type="text"
                    value={editUsername}
                    onChange={(e) => setEditUsername(e.target.value)}
                  />
                ) : (
                  user.username
                )}
              </td>
              <td>
                {editUserId === user.id ? (
                  <input
                    type="text"
                    value={editUserIdInput}
                    onChange={(e) => setEditUserIdInput(e.target.value)}
                  />
                ) : (
                  user.user_id
                )}
              </td>
              <td>
                {editUserId === user.id ? (
                  <input
                    type="text"
                    value={editRole}
                    onChange={(e) => setEditRole(e.target.value)}
                  />
                ) : (
                  user.role
                )}
              </td>
              <td>
                {editUserId === user.id ? (
                  <input
                    type="password"
                    value={editPassword}
                    onChange={(e) => setEditPassword(e.target.value)}
                  />
                ) : (
                  '********'
                )}
              </td>
              <td>
                {editUserId === user.id ? (
                  <div className="userview-button-container">
                    <button className="userview-update-button" onClick={() => handleUpdate(user.id)}>
                      Update
                    </button>
                    <button className="userview-cancel-button" onClick={() => setEditUserId(null)}>
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div className="userview-button-container">
                    <button className="userview-edit-button" onClick={() => handleEdit(user.id, user.username, user.user_id, user.role, user.password)}>
                      Edit
                    </button>
                    <button className="userview-delete-button" onClick={() => handleDelete(user.id)}>
                      Delete
                    </button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(currentUsers.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="userview-container">
      <ToastContainer />
      <h1 className="userview-title">User View</h1>
      <div className="userview-search-container">
        <input
          type="text"
          placeholder="Search by username"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="userview-search-input"
        />
        <button className="userview-search-button" onClick={handleSearch}>
          Search
        </button>
      </div>
      {currentUsers.length === 0 ? (
        <p className="userview-no-data">No users to display.</p>
      ) : (
        <>
          {renderUsers()}
          <div className="userview-pagination-container">
          <button className='navigate-button' onClick={() => handlePaginationClick(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
          {pageNumbers.map((number) => (
            <span key={number} onClick={() => handlePaginationClick(number)} className="userview-pagination-number">
              {number}
            </span>
          ))}
          <button className='navigate-button' onClick={() => handlePaginationClick(currentPage + 1)} disabled={currentPage === Math.ceil(userData.length / usersPerPage)}>
            Next
          </button>
        </div>
        </>
      )}
    </div>
  );
};

export default UserView;
