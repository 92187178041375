import React, { useState } from 'react';
import axios from 'axios';
import './css/Circular.css'
import useAuth from './useAuth';
import apiUrl from './apiConfig';

const Circular = () => {

  const { checkTokenExpiration } = useAuth();
  
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [uploadDate, setUploadDate] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };
  const handlecircular = () => {
    alert("Circular Uploaded ");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('file', file);

      // Include the current date and time
      const currentDate = new Date().toISOString();
      formData.append('upload_date', currentDate);

      const response = await axios.post(`${apiUrl}/upload-circular`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Circular uploaded successfully:', response.data);

      // Update state with the received upload date
      setUploadDate(response.data.upload_date);

      // You can add logic to show a success message or redirect the user after successful upload
    } catch (error) {
      console.error('Error uploading circular:', error);

      // You can add logic to show an error message to the user
    }
  };

  return (
    <div className="circular-container">
      <h2>Circular Upload</h2>
      <form onSubmit={handleSubmit} className="circular-form">
        <div className="circular-input-group">
          <label htmlFor="circular-title" className="circular-label">
            Title:
          </label>
          <input
            type="text"
            id="circular-title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="circular-input"
          />
        </div>
        <div className="circular-input-group">
          <label htmlFor="circular-description" className="circular-label">
            Description:
          </label>
          <textarea
            id="circular-description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            className="circular-textarea"
          ></textarea>
        </div>
        <div className="circular-input-group">
          <label htmlFor="circular-file" className="circular-label">
            Upload File:
          </label>
          <input
            type="file"
            id="circular-file"
            onChange={handleFileChange}
            accept=".pdf, .doc, .docx"
            required
            className="circular-input"
          />
        </div>
        <button type="submit" className="circular-submit-btn" onClick={handlecircular}>
          Upload Circular
        </button>
        <a type="submit" className="circular-update-btn" href='/updatecircular'>
          Update
        </a>
      </form>

      {uploadDate && (
        <div className="circular-upload-date">
          <h3>Upload Date:</h3>
          <p>{uploadDate}</p>
        </div>
      )}
    </div>
  );
};

export default Circular;