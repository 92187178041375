import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Unauthorized from './Unauthorized';

const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const { isAuthenticated, user } = useAuth();

  // Check if the user is authenticated
  if (!isAuthenticated) {
    return <Redirect to="/api/admin/login" />;
  }

  // Check if the user's role is allowed for the specific route
  const isAllowed = allowedRoles ? allowedRoles.includes(user.role) : true;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAllowed ? <Component {...props} /> : <Unauthorized />
      }
    />
  );
};

export default PrivateRoute;
