import React from 'react';
import './css/homepage.css'
import logo from './assets/scl logo.jpg'

const HomePage = () => {
  return (
    <div className="homepage-container">
      {/* Header component */}
      <header className="homepage-header">
        <h1>Welcome to</h1>
        <h2>XXX (SOFTWARE FOR CONTROLLER OF EXAMINATION)</h2>
      </header>

      {/* Featured section component */}
      <section className="homepage-featured-section">
        <div className="homepage-featured-content">
          <h2>ST MARY'S GROUP OF SCHOOLS</h2>
          <p>Raghavan Street Perambur Chennai 600011</p>
        </div>
        <div className="homepage-featured-image">
          {/* Add your featured image here */}
          <img src={logo} alt="ERP Software" className="homepage-image" />
        </div>
      </section>

      {/* Key features component */}
      <section className="homepage-key-features">
        <h2>
          Visit: <a href="https://www.stmarysschools.edu.in/">Official School Website</a>
        </h2>
        <div className="homepage-feature-list">
          <div className="homepage-feature">
            <h3>Student</h3>
          </div>
          <div className="homepage-feature">
            <h3>Internal Staffs</h3>
          </div>
          <div className="homepage-feature">
            <h3>External Staffs</h3>
          </div>
          <div className="homepage-feature">
            <h3>Attendance</h3>
          </div>
          <div className="homepage-feature">
            <h3>Results</h3>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
