import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiUrl from './apiConfig';
import useAuth from './useAuth';
import './css/workupdate.css';

const UpdateWork = () => {
    const { checkTokenExpiration } = useAuth();


  const [homeworks, setHomeworks] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [batches, setBatches] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedBatch, setSelectedBatch] = useState('');
  const [dateAssigned, setDateAssigned] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchHomeworks = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/workupdate`);
        setHomeworks(response.data);
        const uniqueDepartments = [...new Set(response.data.map(hw => hw.department))];
        const uniqueBatches = [...new Set(response.data.map(hw => hw.batch))];
        setDepartments(uniqueDepartments);
        setBatches(uniqueBatches);
      } catch (error) {
        console.error('Error fetching homeworks:', error);
        setError('Failed to fetch homeworks. Please check your network connection and try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchHomeworks();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this homework?')) {
      setLoading(true);
      try {
        await axios.delete(`${apiUrl}/workupdate/${id}`);
        setHomeworks(homeworks.filter((hw) => hw.id !== id));
      } catch (error) {
        console.error('Error deleting homework:', error);
        setError('Failed to delete homework. Please check your network connection and try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFilter = () => {
    setLoading(true);
    setError('');
    let filteredHomeworks = homeworks;

    if (selectedDepartment) {
      filteredHomeworks = filteredHomeworks.filter(hw => hw.department === selectedDepartment);
    }

    if (selectedBatch) {
      filteredHomeworks = filteredHomeworks.filter(hw => hw.batch === selectedBatch);
    }

    if (dateAssigned) {
      filteredHomeworks = filteredHomeworks.filter(hw => hw.date_assigned === dateAssigned);
    }

    setHomeworks(filteredHomeworks);
    setLoading(false);
  };

  return (
    <div className="updatework-container">
      <h2>Update Homework</h2>
      <div className="updatework-filters">
        <div className="updatework-form-group">
          <label htmlFor="department">Department:</label>
          <select
            id="department"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          >
            <option value="">All</option>
            {departments.map((dept, index) => (
              <option key={index} value={dept}>
                {dept}
              </option>
            ))}
          </select>
        </div>
        <div className="updatework-form-group">
          <label htmlFor="batch">Batch:</label>
          <select
            id="batch"
            value={selectedBatch}
            onChange={(e) => setSelectedBatch(e.target.value)}
          >
            <option value="">All</option>
            {batches.map((batch, index) => (
              <option key={index} value={batch}>
                {batch}
              </option>
            ))}
          </select>
        </div>
        <div className="updatework-form-group">
          <label htmlFor="dateAssigned">Date Assigned:</label>
          <input
            type="date"
            id="dateAssigned"
            value={dateAssigned}
            onChange={(e) => setDateAssigned(e.target.value)}
          />
        </div>
        <button onClick={handleFilter}>Apply Filters</button>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {!loading && homeworks.length === 0 && <p>No homework assignments available.</p>}
      <ul className="updatework-homework-list">
        {homeworks.map((hw) => (
          <li key={hw.id} className="updatework-homework-item">
            <p>Subject: {hw.subject_name}</p>
            <p>Details: {hw.homework_details}</p>
            <p>Date Assigned: {new Date(hw.date_assigned).toLocaleDateString()}</p>
            <p>Department: {hw.department}</p>
            <p>Batch: {hw.batch}</p>
            <button onClick={() => handleDelete(hw.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UpdateWork;
