// UserDashboard.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/UserDashboard.css'
import useAuth from './useAuth';
import apiUrl from './apiConfig';

const UserDashboard = () => {

  const { checkTokenExpiration } = useAuth();

  useEffect(() => {
    // Use checkTokenExpiration here or remove the declaration if not needed.
  }, [checkTokenExpiration]);



  const [loginData, setLoginData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(3);

  useEffect(() => {
    // Fetch data from the backend when the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/user_dashboard`);
      setLoginData(response.data);
    } catch (error) {
      console.error('Error fetching login data:', error);
    }
  };
  const handleAdminClick = () => {
    // Add logic for the admin button click event
    window.location.href = '/userview';
    // You can navigate to the admin page or perform any other admin-related action here
  };





  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = loginData.slice(indexOfFirstResult, indexOfLastResult);

  const renderResults = () => {
    return currentResults.map((login) => (
      <tr key={login.id} className="dash-row">
        <td className="dash-data">{login.user_id}</td>
        <td className="dash-data">{login.username}</td>
        <td className="dash-data">{new Date(login.login_time).toLocaleString()}</td>
        <td className="dash-data">{login.private_ip}</td>
        <td className="dash-data">{login.public_ip}</td>
        <td className="dash-data">{login.device_type}</td>
        <td className="dash-data">{login.user_agent}</td>
      </tr>
    ));
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(loginData.length / resultsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, pageNumbers.length));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleDeleteAll = async () => {
    try {
      await axios.delete(`${apiUrl}/delete_all_records`);
      // Refresh the data after deletion
      fetchData();
    } catch (error) {
      console.error('Error deleting records:', error);
    }
  };

  return (
    <div className="dash-user-dashboard">
      <h1 className="dash-title">User Dashboard</h1>
      <button className="dash-admin-button" onClick={handleAdminClick}>
        Admin
      </button>
      <table className="dash-table">
        <thead>
          <tr>
            <th className="dash-header">User ID</th>
            <th className="dash-header">Username</th>
            <th className="dash-header">Login Time</th>
            <th className="dash-header">Private IP</th>
            <th className="dash-header">Public IP</th>
            <th className="dash-header">Device Type</th>
            <th className="dash-header">User Agent</th>
          </tr>
        </thead>
        <tbody>{renderResults()}</tbody>
      </table>
      <div className="dash-pagination">
        <button className="dash-pagination-button" onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span className="dash-page-number">{currentPage}</span>
        <button className="dash-pagination-button" onClick={handleNextPage} disabled={currentPage === pageNumbers.length}>
          Next
        </button>
      </div>
      <br />
      <button className="dash-delete-button" onClick={handleDeleteAll}>
        Delete All Records
      </button>
    </div>
  );
};

export default UserDashboard;
