import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import StudentForm from './components/StudentForm';
import StudentData from './components/StudentData';
import StudentInfo from './components/StudentInfo';
import StudentMarks from './components/StudentMarks';
import MarkUpdate from './components/MarkUpdate';
import Attendance from './components/Attendance';
import ReportView from './components/ReportView';
import SignupPage from './components/SignupPage';
import LoginPage from './components/LoginPage';
import PrivateRoute from './components/PrivateRoute';
import UserDashboard from './components/UserDashboard';
import UserView from './components/UserView';
import StudentLogin from './components/StudentLogin';
import ResultView from './components/ResultView';
import StaffsData from './components/StaffsData';
import StaffsForm from './components/StaffsForm';
import StaffsInfo from './components/StaffsInfo';
import NavigationBar from './components/NavigationBar';
import HomePage from './components/HomePage';
import StaffAttendance from './components/StaffAttendance';
import StaffReport from './components/StaffReport';
import './index.css';
import Circular from './components/Circular';
import UpdateCircular from './components/UpdateCircular';
import StudentCircular from './components/StudentCircular';
import AttendanceView from './components/AttendanceView';
import StaffAttendanceView from './components/StaffAttendanceView';
import Homework from './components/Homework';
import StudentWork from './components/StudentWork';
import UpdateWork from './components/UpdateWork';
import './disableRightClick';

const App = () => {

  return (
    <Router>
      <NavigationBar />
      <Switch>
        <PrivateRoute exact path="/security" component={UserDashboard} allowedRoles={['admin']}/>
        <PrivateRoute exact path="/userview" component={UserView} allowedRoles={['admin']}/>
        <PrivateRoute exact path="/staffform" component={StaffsForm} allowedRoles={['admin']}/>
        <PrivateRoute exact path="/staffdata" component={StaffsData} allowedRoles={['admin']}/>
        <PrivateRoute exact path="/staffinfo" component={StaffsInfo} allowedRoles={['admin']}/>
        <PrivateRoute exact path="/studentform" component={StudentForm} allowedRoles={['admin', 'user']} />
        <PrivateRoute exact path="/studentdata" component={StudentData} allowedRoles={['admin', 'user']} />
        <PrivateRoute exact path="/studentinfo" component={StudentInfo} allowedRoles={['admin', 'user']}/>
        <PrivateRoute exact path="/studentmarks" component={StudentMarks} allowedRoles={['admin', 'user']}/>
        <PrivateRoute exact path="/markupdate" component={MarkUpdate} allowedRoles={['admin']}/>
        <PrivateRoute exact path="/homework" component={Homework} allowedRoles={['admin', 'user']}/>
        <PrivateRoute exact path="/attendance" component={Attendance} allowedRoles={['admin', 'user']}/>   
        <PrivateRoute exact path="/reportview" component={ReportView} allowedRoles={['admin']}/>
        <PrivateRoute exact path="/api/admin/signup" component={SignupPage} allowedRoles={['admin']}/>
        <Route exact path="/api/admin/login" component={LoginPage} />
        <Route exact path="/resultview" component={ResultView} />
        <Route exact path="/studentlogin" component={StudentLogin} />
        <Route exact path="/" component={HomePage} />
        <Route exact path="/circular" component={StudentCircular} />
        <Route exact path="/studentwork" component={StudentWork}/>
        <PrivateRoute exact path="/attendanceview" component={AttendanceView} allowedRoles={['admin', 'user']}/>
        <PrivateRoute exact path="/homeupdate" component={UpdateWork} allowedRoles={['admin' , 'user']}/>
        <PrivateRoute exact path="/staffattendanceview" component={StaffAttendanceView} allowedRoles={['admin', 'user']}/>
        <PrivateRoute exact path="/uploadcircular" component={Circular} allowedRoles={['admin']}/>
        <PrivateRoute exact path="/updatecircular" component={UpdateCircular} allowedRoles={['admin']}/>
        <PrivateRoute exact path="/staffattendance" component={StaffAttendance} allowedRoles={['admin']}/>
        <PrivateRoute exact path="/staffreport" component={StaffReport} allowedRoles={['admin']}/>
        {/* Add more routes for other components as needed */}
      </Switch>
    </Router>
  );
};

export default App;
