// UpdateCircular.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/UpdateCircular.css'
import apiUrl from './apiConfig';
import useAuth from './useAuth';



const UpdateCircular = () => {
  const { checkTokenExpiration } = useAuth();
  
  const [circulars, setCirculars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State for handling editing modal
  const [editingCircular, setEditingCircular] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedFile, setEditedFile] = useState(null);

  useEffect(() => {
    const fetchCirculars = async () => {
      try {
        const response = await axios.get(`${apiUrl}/get-all-circulars`);

        if (response.status === 200) {
          setCirculars(response.data);
        } else {
          setError('Failed to fetch circulars');
        }
      } catch (error) {
        console.error('Error fetching circulars:', error);
        setError('An unexpected error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchCirculars();
  }, []);

  const handleEditClick = (circular) => {
    // Set the circular to be edited and initialize the state with its values
    setEditingCircular(circular);
    setEditedTitle(circular.title);
    setEditedDescription(circular.description);
    setEditedFile(null); // Clear the previous file
  };

  const handleDelete = async (circularId) => {
    try {
      // Delete the circular with the specified ID
      await axios.delete(`${apiUrl}/delete-circular/${circularId}`);

      // Update the circulars state after deletion
      setCirculars((prevCirculars) => prevCirculars.filter((circular) => circular.id !== circularId));
    } catch (error) {
      console.error('Error deleting circular:', error);
    }
  };

  const handleSaveEdit = async () => {
    try {
      // If a new file is chosen, upload it first
      let filename = editingCircular.filename; // Default to the existing filename

      if (editedFile) {
        const formData = new FormData();
        formData.append('file', editedFile);

        const uploadResponse = await axios.post(`${apiUrl}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        filename = uploadResponse.data.filename;
      }

      // Update circular details
      const response = await axios.put(`${apiUrl}/edit-circular/${editingCircular.id}`, {
        title: editedTitle,
        description: editedDescription,
        filename: filename, // Use the new or existing filename
      });

      console.log('Circular edited successfully:', response.data);

      // Update the circulars state after editing
      setCirculars((prevCirculars) =>
        prevCirculars.map((circular) => (circular.id === editingCircular.id ? response.data : circular))
      );

      // Close the editing modal
      setEditingCircular(null);
    } catch (error) {
      console.error('Error editing circular:', error);

      // You can add logic to show an error message to the user
    }
  };

  return (
    <div className="UpdateCircular-container">
      <h2 className="UpdateCircular-title">All Circulars</h2>

      {loading && <p className="UpdateCircular-loading">Loading...</p>}

      {error && <p className="UpdateCircular-error" style={{ color: 'red' }}>{error}</p>}

      {!loading && circulars.length === 0 && <p className="UpdateCircular-message">No circulars available</p>}

      {!loading && circulars.length > 0 && (
        <table className="UpdateCircular-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>File</th>
              <th>Upload Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {circulars.map((circular) => (
              <tr key={circular.id}>
                <td>{circular.title}</td>
                <td>{circular.description}</td>
                <td>
                  <a className="UpdateCircular-file-link" href={`${apiUrl}/uploads/${circular.filename}`} target="_blank" rel="noopener noreferrer">
                    {circular.filename}
                  </a>
                </td>
                <td>{circular.upload_date}</td>
                <td>
                  <button className="UpdateCircular-edit-button" onClick={() => handleEditClick(circular)}>Edit</button>
                  <button className="UpdateCircular-delete-button" onClick={() => handleDelete(circular.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Editing Modal */}
      {editingCircular && (
        <div className="UpdateCircular-edit-modal">
          <h3>Edit Circular</h3>
          <label>Title:</label>
          <input className="UpdateCircular-input" type="text" value={editedTitle} onChange={(e) => setEditedTitle(e.target.value)} />
          <label>Description:</label>
          <textarea className="UpdateCircular-textarea" value={editedDescription} onChange={(e) => setEditedDescription(e.target.value)} />
          <label>Upload new file:</label>
          <input className="UpdateCircular-file-input" type="file" accept=".pdf,.doc,.docx" onChange={(e) => setEditedFile(e.target.files[0])} />
          <button className="UpdateCircular-save-button" onClick={handleSaveEdit}>Save</button>
        </div>
      )}
    </div>
  );
};

export default UpdateCircular;