// AttendanceView.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiUrl from './apiConfig';
import useAuth from './useAuth';
import './css/table.css';

const AttendanceView = () => {

  const { checkTokenExpiration } = useAuth();
  
  const [attendanceData, setAttendanceData] = useState([]);
  const [departments, setDepartments] = useState(['All']);
  const [batches, setBatches] = useState(['All']);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('All');
  const [selectedBatch, setSelectedBatch] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const attendancePerPage = 100; // Adjust the number of items per page

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/attendanceview`, {
          params: {
            date: selectedDate,
            department: selectedDepartment,
            batch: selectedBatch,
          },
        });

        setAttendanceData(response.data);

        // Extract unique departments and batches from the response
        const uniqueDepartments = ['All', ...new Set(response.data.map((attendance) => attendance.department))];
        const uniqueBatches = ['All', ...new Set(response.data.map((attendance) => attendance.batch))];
        setDepartments(uniqueDepartments);
        setBatches(uniqueBatches);
      } catch (error) {
        console.error('Error fetching attendance data:', error);
      }
    };

    fetchAttendanceData();
  }, [selectedDate, selectedDepartment, selectedBatch]);

  // Pagination Logic
  const indexOfLastAttendance = currentPage * attendancePerPage;
  const indexOfFirstAttendance = indexOfLastAttendance - attendancePerPage;
  const currentAttendance = attendanceData
    .sort((a, b) => (a.student_name || 'N/A').localeCompare(b.student_name || 'N/A'))
    .slice(indexOfFirstAttendance, indexOfLastAttendance);

  const renderAttendance = currentAttendance.map((attendance, index) => (
    <tr key={attendance.id}>
      <td>{index + 1}</td>
      <td>{attendance.student_name || 'N/A'}</td>
      <td>{attendance.register_number}</td>
      <td>{attendance.date}</td>
      <td>{attendance.status}</td>
      <td>{attendance.department}</td>
      <td>{attendance.batch}</td>
    </tr>
  ));

  // Pagination Controls
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(attendanceData.length / attendancePerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => (
    <li key={number}>
      <button onClick={() => setCurrentPage(number)}>{number}</button>
    </li>
  ));

  return (
    <div className="checking-attendance-view-container">
      <h2 className="checking-attendance-view-heading">Student Attendance View</h2>
      <div className="checking-attendance-filters">
        <label htmlFor="datePicker" className="checking-attendance-label">
          Select Date:
        </label>
        <input
          type="date"
          id="datePicker"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="checking-attendance-input"
          required
        />

        <label htmlFor="departmentFilter" className="checking-attendance-label">
          Filter by Department:
        </label>
        <select
          id="departmentFilter"
          value={selectedDepartment}
          onChange={(e) => setSelectedDepartment(e.target.value)}
          className="checking-attendance-select"
        >
          {departments.map((department) => (
            <option key={department} value={department}>
              {department}
            </option>
          ))}
        </select>

        <label htmlFor="batchFilter" className="checking-attendance-label">
          Filter by Batch:
        </label>
        <select
          id="batchFilter"
          value={selectedBatch}
          onChange={(e) => setSelectedBatch(e.target.value)}
          className="checking-attendance-select"
        >
          {batches.map((batch) => (
            <option key={batch} value={batch}>
              {batch}
            </option>
          ))}
        </select>
      </div>

      <table className="checking-attendance-view-table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Student Name</th>
            <th>Register Number</th>
            <th>Date</th>
            <th>Status</th>
            <th>Department</th>
            <th>Batch</th>
          </tr>
        </thead>
        <tbody>{renderAttendance}</tbody>
      </table>

      {/* Pagination */}
      <ul className="checking-attendance-pagination">
        <li>
          <button
            onClick={() => setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage))}
            className="checking-attendance-pagination-button"
          >
            Previous
          </button>
        </li>
        <li>
          <button className="checking-attendance-pagination-button">{currentPage}</button>
        </li>
        <li>
          <button
            onClick={() =>
              setCurrentPage((prevPage) =>
                prevPage < Math.ceil(attendanceData.length / attendancePerPage) ? prevPage + 1 : prevPage
              )
            }
            className="checking-attendance-pagination-button"
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  );
};

export default AttendanceView;
