import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/reportview.css';
import useAuth from './useAuth';
import apiUrl from './apiConfig';

const ReportView = () => {
  const { checkTokenExpiration } = useAuth();

  const [registerNumber, setRegisterNumber] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [attendanceDetails, setAttendanceDetails] = useState([]);
  const [updatedAttendance, setUpdatedAttendance] = useState([]);
  const [error, setError] = useState('');
  const [collegeWorkingDays, setCollegeWorkingDays] = useState(0);
  const [studentPresentDays, setStudentPresentDays] = useState(0);
  const [studentAbsentDays, setStudentAbsentDays] = useState(0);
  const [attendancePercentage, setAttendancePercentage] = useState(0);

  const handleInputChange = (e) => {
    setRegisterNumber(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const fetchAttendanceDetails = async () => {
    try {
      let url = `${apiUrl}/reports/${registerNumber}`;
      if (startDate && endDate) {
        url += `?startDate=${startDate}&endDate=${endDate}`;
      }

      const response = await axios.get(url);
      setAttendanceDetails(response.data.attendance);
      setUpdatedAttendance(response.data.attendance.map((detail) => ({ ...detail })));

      // Calculate statistics
      calculateStatistics(response.data.attendance);
    } catch (error) {
      setError('Attendance details not found for this register number');
      setAttendanceDetails([]);
      setUpdatedAttendance([]);
    }
  };

  useEffect(() => {
    if (registerNumber.trim() !== '') {
      fetchAttendanceDetails();
    }
  }, [registerNumber, startDate, endDate]);

  const handleStatusChange = (index, field, value) => {
    const updatedAttendanceCopy = [...updatedAttendance];
    updatedAttendanceCopy[index][field] = value;
    setUpdatedAttendance(updatedAttendanceCopy);
  };

  const handleSaveAttendance = async () => {
    try {
      await Promise.all(
        updatedAttendance.map(async (detail) => {
          await axios.post(`${apiUrl}/update-attendance/${registerNumber}`, {
            date: detail.date,
            status: detail.status,
          });
        })
      );
      setError('');
      fetchAttendanceDetails();

      toast.success('Attendance saved successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      setError('Failed to update attendance');

      toast.error('Failed to update attendance!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleDeleteAll = async () => {
    try {
      await axios.delete(`${apiUrl}/delete-all/${registerNumber}`);
      setError('');
      fetchAttendanceDetails();

      toast.success('All records deleted successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      setError('Failed to delete all records');

      toast.error('Failed to delete all records!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };


  const handleDeleteAttendance = async (dateToDelete) => {
    try {
      await axios.delete(`${apiUrl}/delete-attendance/${registerNumber}/${dateToDelete}`);
      setError('');
      fetchAttendanceDetails();

      toast.success('Attendance deleted successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      setError('Failed to delete attendance');

      toast.error('Failed to delete attendance!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const calculateStatistics = (attendanceList) => {
    const collegeDays = new Set();
    let presentCount = 0;
    let absentCount = 0;

    attendanceList.forEach((detail) => {
      collegeDays.add(detail.date);
      if (detail.status === 'present') {
        presentCount++;
      } else {
        absentCount++;
      }
    });

    setCollegeWorkingDays(collegeDays.size);
    setStudentPresentDays(presentCount);
    setStudentAbsentDays(absentCount);

    const totalDays = presentCount + absentCount;
    const percentage = totalDays !== 0 ? (presentCount / totalDays) * 100 : 0;
    setAttendancePercentage(percentage.toFixed(2));
  };

  return (
  <div className="report-container">
  <h2 className="report-heading">Attendance Report View</h2>
  <form
    className="report-form"
    onSubmit={(e) => {
      e.preventDefault();
      fetchAttendanceDetails();
    }}
  >
    <label className="report-label">
      Enter Register Number:
      <input type="text" value={registerNumber} onChange={handleInputChange} className="report-input" />
    </label>
    <label className="report-label">
      Start Date:
      <br />
      <input type="date" value={startDate} onChange={handleStartDateChange} className="report-input" />
    </label>
    <label className="report-label">
      End Date:
      <br />
      <input type="date" value={endDate} onChange={handleEndDateChange} className="report-input" />
    </label>
    <button type="submit" className="report-button">Get Attendance Details</button>
    <button onClick={handleDeleteAll} className="report-button">Delete All</button>
  </form>
  {error && <p className="report-error-message">{error}</p>}
  <div className="report-statistics-section">
    <h3 className="report-subheading">Attendance Details for Register Number: {registerNumber}</h3>
    <p className="report-statistics">College Working Days: {collegeWorkingDays}</p>
    <p className="report-statistics">Student Present Days: {studentPresentDays}</p>
    <p className="report-statistics">Student Absent Days: {studentAbsentDays}</p>
    <p className="report-statistics">Attendance Percentage: {attendancePercentage}%</p>
    <table className="report-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {attendanceDetails.map((detail, index) => (
          <tr key={index}>
            <td>
              <input
                type="date"
                value={updatedAttendance[index].date}
                onChange={(e) => handleStatusChange(index, 'date', e.target.value)}
                className="report-input"
              />
            </td>
            <td>
              <select
                value={updatedAttendance[index].status}
                onChange={(e) => handleStatusChange(index, 'status', e.target.value)}
                className="report-select"
              >
                <option value="present">Present</option>
                <option value="absent">Absent</option>
              </select>
            </td>
            <td>
              <button onClick={() => handleDeleteAttendance(detail.date)} className="report-action-button">Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <button onClick={handleSaveAttendance} className="report-save-button">Save Attendance</button>
  </div>
  <ToastContainer />
</div>
  );
};

export default ReportView;
