import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/Attendance.css'; // Add your CSS file for styling
import useAuth from './useAuth';
import apiUrl from './apiConfig';

const StaffAttendance = () => {
  const { checkTokenExpiration } = useAuth();

  const [staffAttendance, setStaffAttendance] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [departments, setDepartments] = useState([]);
  const [jobTitles, setJobTitles] = useState(['All']);
  const [attendanceData, setAttendanceData] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState('All');
  const [selectedJobTitle, setSelectedJobTitle] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const staffPerPage = 70;
  

  const fetchStaffAttendance = async () => {
    try {
      const response = await axios.get(`${apiUrl}/staffattendance`, {
        params: {
          department: selectedDepartment,
          job_title: selectedJobTitle,
        },
      });
      setStaffAttendance(response.data);
      const initialAttendanceData = {};
      response.data.forEach((staff) => {
        initialAttendanceData[staff.employee_id] = '';
      });
      setAttendanceData(initialAttendanceData);

      const uniqueDepartments = ['All', ...new Set(response.data.map((staff) => staff.department))];
      setDepartments(uniqueDepartments);

      const uniqueJobTitles = ['All', ...new Set(response.data.map((staff) => staff.job_title))];
      setJobTitles(uniqueJobTitles);
    } catch (error) {
      console.error('Error fetching staff attendance:', error);
    }
  };

  useEffect(() => {
    fetchStaffAttendance();
  }, [selectedDepartment, selectedJobTitle]); // Refetch data when the department or job_title changes

  const handleAttendanceChange = (employeeId, status) => {
    setAttendanceData((prevData) => ({
      ...prevData,
      [employeeId]: status,
    }));
  };

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  const handleJobTitleChange = (e) => {
    setSelectedJobTitle(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleSaveAttendance = async () => {
    try {
      const updatePromises = [];

      // Handle status updates for each staff
      Object.keys(attendanceData).forEach((employeeId) => {
        const status = attendanceData[employeeId];
        if (status === 'absent' || status === 'present') {
          updatePromises.push(
            axios.post(`${apiUrl}/staffattendance/` + employeeId, {
              date: selectedDate,
              status: status,
            })
          );
        }
      });

      // If marking 'absent', set remaining staff to 'present'
      if (Object.values(attendanceData).some((status) => status === 'absent')) {
        const remainingStaff = staffAttendance
          .filter(
            (staff) =>
              !attendanceData[staff.employee_id] &&
              ((selectedDepartment === 'All' || staff.department === selectedDepartment) &&
                (selectedJobTitle === 'All' || staff.job_title === selectedJobTitle) &&
                (staff.staff_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  staff.employee_id.toLowerCase().includes(searchTerm.toLowerCase()))
              )
          )
          .map((staff) =>
            axios.post(`${apiUrl}/staffattendance/` + staff.employee_id, {
              date: selectedDate,
              status: 'present',
            })
          );
        updatePromises.push(...remainingStaff);
      }

      await Promise.all(updatePromises);
      fetchStaffAttendance();
      toast.success('Staff Attendance Updated Successfully');
    } catch (error) {
      console.error('Error saving staff attendance:', error);
      toast.success('Staff Attendance Updated Successfully');
    }
  };
  
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(staffAttendance.length / staffPerPage);
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const indexOfLastStaff = currentPage * staffPerPage;
  const indexOfFirstStaff = indexOfLastStaff - staffPerPage;
  const currentStaff = staffAttendance
    .filter(
      (staff) =>
        (selectedDepartment === 'All' || staff.department === selectedDepartment) &&
        (selectedJobTitle === 'All' || staff.job_title === selectedJobTitle) &&
        (staff.staff_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          staff.employee_id.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .slice(indexOfFirstStaff, indexOfLastStaff);

  const renderStaff = currentStaff
    .sort((a, b) => a.employee_id.localeCompare(b.employee_id))
    .map((staff) => (
      <tr key={staff.id}>
        <td>{staff.staff_name}</td>
        <td>{staff.employee_id}</td>
        <td>
          <select
            value={attendanceData[staff.employee_id]}
            onChange={(e) => handleAttendanceChange(staff.employee_id, e.target.value)}
            className="attendance-select"
          >
            <option value="">Select status</option>
            <option value="present">Present</option>
            <option value="absent">Absent</option>
          </select>
        </td>
      </tr>
    ));

  return (
    <div className="attendance-container">
      <h2 className="attendance-heading">Staff Attendance Management</h2>
      <div className="attendance-filters">
        <label htmlFor="datePicker" className="attendance-label">
          Select Date:
        </label>
        <input
          type="date"
          id="datePicker"
          value={selectedDate}
          onChange={handleDateChange}
          className="attendance-input"
          required
        />
        <label htmlFor="departmentFilter" className="attendance-label">
          Filter by Department:
        </label>
        <select
          id="departmentFilter"
          value={selectedDepartment}
          onChange={handleDepartmentChange}
          className="attendance-select"
        >
          {departments.map((department) => (
            <option key={department} value={department}>
              {department}
            </option>
          ))}
        </select>

        <label htmlFor="jobTitleFilter" className="attendance-label">
          Filter by Job Title:
        </label>
        <select
          id="jobTitleFilter"
          value={selectedJobTitle}
          onChange={handleJobTitleChange}
          className="attendance-select"
        >
          {jobTitles.map((jobTitle) => (
            <option key={jobTitle} value={jobTitle}>
              {jobTitle}
            </option>
          ))}
        </select>

        <label htmlFor="searchInput" className="attendance-label">
          Search:
        </label>
        <input
          type="text"
          id="searchInput"
          value={searchTerm}
          onChange={handleSearch}
          className="attendance-input"
        />
        <a href='/staffreport' className="attendance-save-button">
        Update
      </a>
      </div>
      <table className="attendance-table">
        <thead>
          <tr>
            <th>Staff Name</th>
            <th>Employee ID</th>
            <th>Attendance Status</th>
          </tr>
        </thead>
        <tbody>{renderStaff}</tbody>
      </table>

      <ul className="attendance-pagination">
        <li>
          <button onClick={handlePrevPage} className="attendance-pagination-button">
            Previous
          </button>
        </li>
        <li>
          <button className="attendance-pagination-button">{currentPage}</button>
        </li>
        <li>
          <button onClick={handleNextPage} className="attendance-pagination-button">
            Next
          </button>
        </li>
      </ul>
      <button onClick={handleSaveAttendance} className="attendance-save-button">
        Save
      </button>
      <ToastContainer />
    </div>
  );
};

export default StaffAttendance;
