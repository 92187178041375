import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './css/LoginPage.css';
import apiUrl from './apiConfig';

// Function to parse JWT
const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Error parsing JWT:', error);
    return null;
  }
};

const Login = () => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${apiUrl}/login` , {
        userId: userId,
        password: password,
      });

      console.log('Login Response:', response);

      if (response.status === 200) {
        const token = response.data.access_token;

        if (token) {
          localStorage.setItem('token', token);
          localStorage.setItem('iat', parseJwt(token).iat);
          window.location.href = '/';
        } else {
          console.error('Token is missing');
        }
      } else {
        console.error('Invalid credentials');
        // Display an error message to the user
        // For example, set a state to show an error message on the UI
      }
    } catch (error) {
      console.error('Login error:', error);
      // Display an error message to the user
      // For example, set a state to show an error message on the UI
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h1>Login</h1>
        <input
          type="text"
          placeholder="User ID"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
        <p className="signup-link">
          Don't have an account? <a href="/api/admin/signup">Sign Up</a>
        </p>
      </form>
    </div>
  );
};

export default Login;
