// StudentLogin.js (updated)

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/studentlogin.css'
import apiUrl from './apiConfig';


const StudentLogin = () => {
  const [registerNumber, setRegisterNumber] = useState('');
  const [dob, setDob] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [userInput, setUserInput] = useState('');
  const history = useHistory();

  useEffect(() => {
    createCaptcha();

    document.addEventListener('contextmenu', handleRightClick);
    document.addEventListener('copy', handleCopy);

    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
      document.removeEventListener('copy', handleCopy);
      toast.dismiss();

    };
  }, []); // Run only once on component mount

  const createCaptcha = () => {
    var charsArray =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*';
    var lengthOtp = 6;
    var captcha = [];
    for (var i = 0; i < lengthOtp; i++) {
      var index = Math.floor(Math.random() * charsArray.length);
      captcha.push(charsArray[index]);
    }
    setCaptcha(captcha.join(''));
  };
  const handleRightClick = (e) => {
    e.preventDefault();
  };

  const handleCopy = (e) => {
    e.preventDefault();
    alert('Copying is not allowed.');
  };


  const handleLogin = async () => {
    try {
      if (userInput.toLowerCase() !== captcha.toLowerCase()) {
        createCaptcha(); // Regenerate CAPTCHA
        throw new Error('Invalid CAPTCHA. Please try again.');
      }

      const response = await fetch(`${apiUrl}/api/studentlogin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ registerNumber, dob }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('access_token', data.access_token);
        window.location.href = '/resultview';
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error during login:', error);
      toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
    }
  };

  const handleRegenerateCaptcha = () => {
    createCaptcha(); // Regenerate CAPTCHA
  };

  return (
    <div className="studentlogin-container">
      <h2 className="studentlogin-heading">Student Login</h2>
      <div className="studentlogin-input-container">
        <label className="studentlogin-label">Register Number:</label>
        <input
          type="text"
          value={registerNumber}
          onChange={(e) => setRegisterNumber(e.target.value)}
          className="studentlogin-input"
        />
      </div>
      <div className="studentlogin-input-container">
        <label className="studentlogin-label">Date of Birth: [DD/MM/YYYY]</label>
        <input
          type="text"
          value={dob}
          onChange={(e) => setDob(e.target.value)}
          className="studentlogin-input"
        />
      </div>
      <div className="studentlogin-input-container">
        <label className="studentlogin-label">Enter CAPTCHA:</label>
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          className="studentlogin-input"
        />
        <span className="studentlogin-captcha" style={{ marginLeft: '10px' }}>
          {captcha}
        </span>
        <button
          onClick={handleRegenerateCaptcha}
          className="studentlogin-regenerate-button"
        >
          Regenerate
        </button>
      </div>
      <button onClick={handleLogin} className="studentlogin-login-button">
        Login
      </button>

      {/* Toast container for displaying error messages */}
      <ToastContainer autoClose={5000} />
    </div>
  );
};

export default StudentLogin;
