import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/StudentInfo.css'; // Import CSS file for styling
import useAuth from './useAuth';
import apiUrl from './apiConfig';

const StaffsInfo = () => {
  const { checkTokenExpiration } = useAuth();

  const [employeeId, setEmployeeId] = useState('');
  const [staffDetails, setStaffDetails] = useState({});
  const [updatedDetails, setUpdatedDetails] = useState({});
  const [error, setError] = useState('');
  const [showUpdateFields, setShowUpdateFields] = useState(false); // State to toggle update fields visibility
  const [newProfilePic, setNewProfilePic] = useState(null); // State for new profile picture

  useEffect(() => {
    setUpdatedDetails(staffDetails); // Update updatedDetails when staffDetails changes
  }, [staffDetails]);

  const fieldOrder = [
    'staff_name',
    'department',
    'batch',
    'dob',
    'gender',
    'employee_id',
    'employee_status',
    'employment_status',
    'educational_qualification',
    'religion',
    'community',
    'staff_phone',
    'email',
    'spouse_name',
    'address',
    'state',
    'country',
    'pin_code',
    'boarding_status',
    'date_of_joining',
  ];

  const fetchStaffDetails = async () => {
    try {
      const response = await axios.get(`${apiUrl}/staffdata/${employeeId}`);
      setStaffDetails(response.data);
      setError('');
    } catch (error) {
      console.error('Error fetching staff details:', error);
      setError('Invalid Employee ID!');
      window.alert('Invalid Employee ID!');
    }
  };

  const updateStaffDetails = async () => {
    try {
      const response = await axios.put(`${apiUrl}/staffdata/${employeeId}`, updatedDetails);
      console.log(response.data); // Log the response or handle it accordingly
      fetchStaffDetails(); // Fetch updated details after the update
      setShowUpdateFields(false); // Hide update fields after a successful update
      window.alert('Staff Details Updated')
      window.location.reload()
    } catch (error) {
      console.error('Error updating staff details:', error);
    }
  };

  const deleteStaff = async () => {
    try {
      await axios.delete(`${apiUrl}/staffdata/${employeeId}`);
      window.alert('Staff member deleted successfully!');
      setStaffDetails({}); // Clear staff details after deletion
    } catch (error) {
      console.error('Error deleting staff member:', error);
      window.alert('Error deleting staff member. Please try again.');
    }
  };

  const changeProfilePic = async () => {
    try {
      const formData = new FormData();
      formData.append('newProfilePic', newProfilePic);

      const response = await axios.put(`${apiUrl}/staffpic/${employeeId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response.data);
      fetchStaffDetails();
      window.alert('Staff photo Updated')
      window.location.reload()
    } catch (error) {
      console.error('Error changing profile picture:', error);
    }
  };

  const handleFileChange = (e) => {
    setNewProfilePic(e.target.files[0]);
  };

  const handleFieldChange = (field, value) => {
    setUpdatedDetails({ ...updatedDetails, [field]: value });
  };

  const renderTable = () => {
    return (
      <table className="info-details-table">
        <tbody>
          {fieldOrder.map((key) => (
            <tr key={key}>
              <td className="info-field-label">{formatHeader(key)}:</td>
              <td className="info-field-input">
                {showUpdateFields ? (
                  <input
                    className="info-update-text"
                    type="text"
                    placeholder={`Enter updated ${key.toLowerCase()}`}
                    value={updatedDetails[key] || ''}
                    onChange={(e) => handleFieldChange(key, e.target.value)}
                  />
                ) : (
                  <span>{staffDetails[key]}</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  // Function to format headers by replacing underscores with spaces and capitalizing words
  const formatHeader = (header) => {
    return header
      .replace(/_/g, ' ') // Replace underscores with spaces
      .split(' ') // Split into words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join words with spaces
  };

  return (
    <div className="info-student-info-container">
      <div className='info-title3'>
        <h2>Staff Information</h2>
      </div>
      <div className="info-center">
        <div className="info-search-section">
          <input
            type="text"
            className="info-register"
            placeholder="Enter Employee ID"
            value={employeeId}
            onChange={(e) => setEmployeeId(e.target.value)}
          />
          <button className="info-details" onClick={fetchStaffDetails}>
            Details
          </button>
        </div>
      </div>

      <div className="info-staff-details">
        {Object.keys(staffDetails).length > 0 && (
          <div>
            <div className="info-student-photo-container">
              <img
                className="info-student-photo"
                src={`${apiUrl}/staffpic/${staffDetails.employee_id}`}
                alt="Staff"
              />
            </div>
            {renderTable()}
            <p className="info-error-message">{error}</p>
            {!showUpdateFields ? (
              <div>
              <button className="info-change" onClick={() => setShowUpdateFields(true)}>
                Change
              </button>
              <button className="info-delete" onClick={deleteStaff}>
              Delete
            </button>
            </div>
            ) : (
              <>
                <button className="info-change" style={{ width: '50%', marginTop: '20px' }} onClick={updateStaffDetails}>
                  Update
                </button>
                <div className="info-change-profile-pic">
                  <input className="info-file" type="file" accept="image/*" onChange={handleFileChange} />
                  <button className="info-change" style={{ width: '50%', marginTop: '20px' }} onClick={changeProfilePic}>
                    Change Profile Pic
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StaffsInfo;
