import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/StudentMark.css';
import { useHistory , Link } from 'react-router-dom';
import useAuth from './useAuth';
import apiUrl from './apiConfig';

const StudentMark = () => {

  const [registerNumber, setRegisterNumber] = useState('');
  const [studentName, setStudentName] = useState('');
  const [subjectMarks, setSubjectMarks] = useState([]);
  const [results, setResults] = useState([]);
  const [showMarks, setShowMarks] = useState(false);
  const [examName, setExamName] = useState('');
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [totalMarks, setTotalMarks] = useState(0);
  const [cumulativeMarks, setCumulativeMarks] = useState(0);



  const history = useHistory();
  const { checkTokenExpiration } = useAuth();
  
  const fetchStudentName = async () => {
    try {
      const response = await axios.get(`${apiUrl}/studentdata/${registerNumber}`);
      setStudentName(response.data.name);
    } catch (error) {
      console.error('Error fetching student name:', error);
      setStudentName('');
    }
  };

  useEffect(() => {
    if (registerNumber) {
      fetchStudentName();
    }
  }, [registerNumber]);

  const addSubject = () => {
    setSubjectMarks([...subjectMarks, { exam: examName, grade: '', subject: '', marks: 0, totalMarks }]);
  };

  const handleSubjectChange = (index, event) => {
    const updatedMarks = [...subjectMarks];
    updatedMarks[index][event.target.name] = event.target.value;
    setSubjectMarks(updatedMarks);
  };

  const saveMarks = async () => {
    try {
      await axios.post(`${apiUrl}/studentmarks/${registerNumber}`, { subjectMarks, totalMarks });
      alert('Marks saved successfully!');
      generateResults();
    } catch (error) {
      console.error('Error saving marks:', error);
    }
  };

  const showStudentMarks = async () => {
    try {
      const response = await axios.get(`${apiUrl}/studentmarks/${registerNumber}`);
      console.log('Received Data:', response.data);  // Add this line for debugging
  
      setResults(response.data);
      setShowMarks(true);
      setShowUpdateButton(true);
    } catch (error) {
      console.error('Error fetching marks:', error);
    }
  };
  

  const generateResults = () => {
    const calculatedResults = subjectMarks.map((subject) => {
      console.log('Subject Marks:', subject.marks, 'Total Marks:', subject.totalMarks);

      const result =
        subject.totalMarks === '50' ? (subject.marks > 20 ? 'Pass' : 'Fail') :
        subject.totalMarks === '100' ? (subject.marks > 40 ? 'Pass' : 'Fail') :
        'Result Not Available';
  
      return {
        ...subject,
        result: result,
      };
    });
  
    // Calculate Cumulative Marks
    const totalCumulativeMarks = calculatedResults.reduce((total, subject) => total + subject.marks, 0);
    setCumulativeMarks(totalCumulativeMarks);
  
    setResults(calculatedResults);
  };
  
  
  

  const groupedMarks = results.reduce((acc, mark) => {
    if (!acc[mark.exam]) {
      acc[mark.exam] = [];
    }
    acc[mark.exam].push(mark);
    return acc;
  }, {});

  const handleLogout = async () => {
    try {
      await axios.post(`${apiUrl}/logout`);
      localStorage.removeItem('token');
      localStorage.removeItem('iat');
      history.push('/api/admin/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <div className="mark-unique-container-one">
      <div>
        <h2 className="mark-unique-form-title">Student Marks</h2>
        <input
          className="mark-unique-mark-input"
          type="text"
          placeholder="Enter Register Number"
          value={registerNumber}
          onChange={(e) => setRegisterNumber(e.target.value)}
        />
        <input
          className="mark-unique-mark-input"
          type="text"
          placeholder="Enter Exam Name"
          value={examName}
          onChange={(e) => setExamName(e.target.value)}
        />
        <input
          className="mark-unique-mark-input"
          type="number"
          placeholder="Enter Total Marks"
          value={totalMarks}
          onChange={(e) => setTotalMarks(parseInt(e.target.value, 10))}
        />

        <button className="mark-unique-add" onClick={addSubject}>
          Add Subject
        </button>
        <button className="mark-unique-save" onClick={saveMarks}>
          Save Marks
        </button>
        <button className="mark-unique-show" onClick={showStudentMarks}>
          Show Marks
        </button>
        {showUpdateButton && (
          <Link to="/markupdate">
            <button className="mark-unique-update">Update</button>
          </Link>
        )}
        <button onClick={handleLogout} className="mark-unique-logout">Logout</button>
        <hr />
        {!showMarks ? (
          <div>
            {subjectMarks.map((subject, index) => (
              <div key={index}>
                <input
                  type="text"
                  placeholder="Enter Grade"
                  name="grade"
                  value={subject.grade}
                  onChange={(e) => handleSubjectChange(index, e)}
                  className="mark-unique-mark-input"
                />
                <input
                  type="text"
                  placeholder="Enter Subject"
                  name="subject"
                  value={subject.subject}
                  onChange={(e) => handleSubjectChange(index, e)}
                  className="mark-unique-mark-input"
                />
                <input
                  type="number"
                  placeholder="Enter Marks"
                  name="marks"
                  value={subject.marks}
                  onChange={(e) => handleSubjectChange(index, e)}
                  className="mark-unique-mark-input"
                />
              </div>
            ))}
          </div>
        ) : (
          <>
              {Object.entries(groupedMarks).map(([exam, examMarks], index) => (
        <div key={index} className="mark-unique-exam-container">
          <h4>{`Exam Name: ${exam}`}</h4>
          <div>{studentName && <h4>{`Student Name: ${studentName}`}</h4>}</div>
          <p>{`Total Marks: ${examMarks[0].total_marks}`}</p>
          <table className="mark-unique-exam-table" key={index}>
            <thead>
              <tr>
                <th>Grade</th>
                <th>Subject</th>
                <th>Marks</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              {examMarks.map((item, idx) => (
                <tr key={idx}>
                  <td>{item.grade}</td>
                  <td>{item.subject}</td>
                  <td>{item.marks}</td>
                  <td>{item.result}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
          <h4>{`Total: ${examMarks.reduce((sum, item) => sum + item.marks, 0)}` }</h4>
          </div>
        </div>
      ))}
    </>
        )}
      </div>
    </div>
  );
};

export default StudentMark;
