import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import apiUrl from './apiConfig';



// Create a context to manage authentication state
const AuthContext = createContext();

// Custom hook to access the authentication context
export const useAuth = () => useContext(AuthContext);

// Function to decode the base64 part of the JWT
const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(decodeURIComponent(atob(base64)));
  } catch (error) {
    console.error('Error parsing JWT:', error);
    return null;
  }
};

// AuthProvider component to wrap your entire application
export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
    loading: true,
  });

  // Function to update the authentication state
  const setAuth = (isAuthenticated, user = null) => {
    setAuthState({
      isAuthenticated,
      user,
      loading: false,
    });
  };

  // Function to handle user logout
  const logout = () => {
    // Clear token from storage
    localStorage.removeItem('token');
    localStorage.removeItem('iat');
    // Update authentication state
    setAuth(false);
  };

  // Effect to check authentication status on component mount
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const token = localStorage.getItem('token');

        if (token) {
          const decodedToken = parseJwt(token);
          const userId = decodedToken.sub; // Assuming 'sub' is the key for user ID in the JWT
          
          const response = await axios.get(`${apiUrl}/check-auth/${userId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          // If the server responds with a user object, the user is authenticated
          setAuth(true, response.data.user);
          
        } else {
          // If no token is present, the user is not authenticated
          setAuth(false);
        }
      } catch (error) {
        // If there is an error or the server responds with an error status, the user is not authenticated
        setAuth(false);
      }
    };

    checkAuthStatus();
  }, []); // Run this effect only once on component mount

  return (
    <AuthContext.Provider value={{ ...authState, setAuth, logout }}>
      {authState.loading ? <p>Loading...</p> : children}
    </AuthContext.Provider>
  );
};
