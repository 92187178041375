import React, { useState } from 'react';
import axios from 'axios';
import './css/StudentForm.css'
import useAuth from './useAuth';
import apiUrl from './apiConfig';

const StudentForm = () => {
  const { checkTokenExpiration } = useAuth();


  const [formData, setFormData] = useState({
    name: '',
    department: '',
    batch: '',
    dob: '',
    gender: '',
    register_number: '',
    id_number: '',
    religion: '',
    community: '',
    student_phone: '',
    email: '',
    guardian_name: '',
    address: '',
    state: '',
    country: '',
    pin_code: '',
    boarding_status: '',
    admission_quota: ''

  });

  const [photo, setPhoto] = useState(null);
  const [extraFields, setExtraFields] = useState([]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };



  const handleFileChange = (e) => {
    setPhoto(e.target.files[0]);
  };


  const handleAddField = () => {
    setExtraFields([...extraFields, { name: '', value: '' }]);
  };

  const handleExtraFieldNameChange = (index, value) => {
    const updatedFields = [...extraFields];
    updatedFields[index].name = value;
    setExtraFields(updatedFields);
  };


  const handleExtraFieldValueChange = (index, value) => {
    const updatedFields = [...extraFields];
    updatedFields[index].value = value;
    setExtraFields(updatedFields);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const allFields = new FormData();

        allFields.append('name', formData.name);
        allFields.append('department', formData.department);
        allFields.append('batch', formData.batch);
        allFields.append('dob', formData.dob);
        allFields.append('gender', formData.gender);
        allFields.append('register_number', formData.register_number);
        allFields.append('id_number', formData.id_number);
        allFields.append('religion', formData.religion);
        allFields.append('community', formData.community);
        allFields.append('student_phone', formData.student_phone);
        allFields.append('email', formData.email);
        allFields.append('guardian_name', formData.guardian_name);
        allFields.append('address', formData.address);
        allFields.append('state', formData.state);
        allFields.append('country', formData.country);
        allFields.append('pin_code', formData.pin_code);
        allFields.append('boarding_status', formData.boarding_status);
        allFields.append('admission_quota', formData.admission_quota);

        if (photo) {
            allFields.append('photo', photo);
          }
          extraFields.forEach((field, index) => {
            allFields.append(`field_${index + 1}_${field.name}`, field.value);
          });

      const response = await axios.post(`${apiUrl}/students`, allFields);
      console.log(response.data); // Log response from Flask backend
      // Optionally, reset form fields after successful submission
      setFormData({
        name: '',
        department: '',
        batch: '',
        dob: '',
        gender: '',
        register_number: '',
        id_number: '',
        religion: '',
        community: '',
        student_phone: '',
        email: '',
        guardian_name: '',
        address: '',
        state: '',
        country: '',
        pin_code: '',
        boarding_status: '',
        admission_quota: ''
        // Reset other fields as needed
      });
      setPhoto(null);
      setExtraFields([]);
    } catch (error) {
      console.error('Error adding student:', error);
    }
  };

  return (
    <div id='container'>
    <div className="form-container">
      <h2 className="form-title">Student Information Form</h2>
      <form onSubmit={handleSubmit} className="form">
        <label>
          Name:
          <input type="text" name="name" value={formData.name} onChange={handleChange} required/>
        </label>
        <label>
          Department:
          <input type="text" name="department" value={formData.department} onChange={handleChange} required/>
        </label>
        {/* Add other input fields for student information */}

        <label>
        Batch:
          <input type="text" name="batch" value={formData.batch} onChange={handleChange} required/>
        </label>

        <label>
          Date of birth : 
          <input type="text" name="dob" value={formData.dob} onChange={handleChange} required/>
        </label>


        <label>
          Gender :
          <input type="text" name="gender" value={formData.gender} onChange={handleChange} required/>
        </label>


        <label>
          Register Number :
          <input type="text" name="register_number" value={formData.register_number} onChange={handleChange} required/>
        </label>


        <label>
        ID Number :
          <input type="text" name="id_number" value={formData.id_number} onChange={handleChange} required/>
        </label>


        <label>
         Religion :
          <input type="text" name="religion" value={formData.religion} onChange={handleChange} required/>
        </label>

        <label>
        Community:
          <input type="text" name="community" value={formData.community} onChange={handleChange} required/>
        </label>

        <label>
         Student Phone:
          <input type="text" name="student_phone" value={formData.student_phone} onChange={handleChange} required/>
        </label>

        <label>
          Email:
          <input type="text" name="email" value={formData.email} onChange={handleChange} required/>
        </label>

        <label>
        Parent / Guardian Name:
          <input type="text" name="guardian_name" value={formData.guardian_name} onChange={handleChange} required/>
        </label>

        <label>
        Address:
          <input type="text" name="address" value={formData.address} onChange={handleChange} required/>
        </label>

        <label>
        State:
          <input type="text" name="state" value={formData.state} onChange={handleChange} required/>
        </label>


        <label>
          Country:
          <input type="text" name="country" value={formData.country} onChange={handleChange} required />
        </label>


        <label>
        Pincode:
          <input type="text" name="pin_code" value={formData.pin_code} onChange={handleChange} required/>
        </label>

        <label>
        Boarding Status:
          <input type="text" name="boarding_status" value={formData.boarding_status} onChange={handleChange} required/>
        </label>


        <label>
        Admission Quota:
          <input type="text" name="admission_quota" value={formData.admission_quota} onChange={handleChange} required/>
        </label>

        <label className="label-photo">
          Photo:
          <input type="file" onChange={handleFileChange} className="input-photo" required />
        </label>



        {extraFields.map((field, index) => (
          <div key={index}>
            <label>
              Field Name:
              <input
                type="text"
                value={field.name}
                onChange={(e) => handleExtraFieldNameChange(index, e.target.value)}
                required
              />
            </label>
            <label>
              Field Value:
              <input
                type="text"
                value={field.value}
                onChange={(e) => handleExtraFieldValueChange(index, e.target.value)}
                required
              />
            </label>
          </div>
        ))}
        <div className="button-container">


        <button type="submit" className="submit-button">Submit</button>
        </div>
      </form>
    </div>
    </div>
  );
};

export default StudentForm;
