// SignUp.js

import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/SignupPage.css';
import useAuth from './useAuth';
import apiUrl from './apiConfig';

const SignUp = () => {

  const { checkTokenExpiration } = useAuth();
  
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('user'); // Default role is 'user'

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
    } else {
      try {
        const response = await axios.post(`${apiUrl}/signup`, {
          username,
          userId,
          password,
          role,
        });
        if (response.status === 201) {
          toast.success('User created successfully');
          // Clear form after successful signup
          setUsername('');
          setUserId('');
          setPassword('');
          setConfirmPassword('');
        }
      } catch (error) {
        toast.error('Error creating user');
        console.error('Error:', error);
      }
    }
  };

  return (
    <div className="signup-container">
      <form className="signup-form" onSubmit={handleSignUp}>
        <h1>Sign Up</h1>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="User ID"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <label>
          Role:
          <select className='role' value={role} onChange={(e) => setRole(e.target.value)}>
            <option value="admin">Admin</option>
            <option value="user">User</option>
            <option value="guest">Guest</option>
          </select>
        </label>
        <button type="submit">Sign Up</button>
        <p className="login-link">
          Already have an account? <a href="/api/admin/login">Login</a>
        </p>
      </form>
      <ToastContainer />
    </div>
  );
};

export default SignUp;
