// StudentCircular.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/StudentCircular.css';
import apiUrl from './apiConfig';

const StudentCircular = () => {
  const [circulars, setCirculars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCirculars = async () => {
      try {
        const response = await axios.get(`${apiUrl}/get-all-circulars`);

        if (response.status === 200) {
          setCirculars(response.data);
        } else {
          setError('Failed to fetch circulars');
        }
      } catch (error) {
        console.error('Error fetching circulars:', error);
        setError('An unexpected error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchCirculars();
  }, []);

  return (
    <div className="student-circular">
      <h2>Student Circulars</h2>

      {loading && <p>Loading...</p>}

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {!loading && circulars.length === 0 && <p>No circulars available</p>}

      {!loading && circulars.length > 0 && (
        <div>
          {circulars.map((circular) => (
            <div className="circular-item" key={circular.id}>
              <h3>{circular.title}</h3>
              <p className="description">Description: {circular.description}</p>
              <p className="date">Date: {circular.upload_date}</p>
              <img
                src={`${apiUrl}/uploads/${circular.filename}`}
                alt={`Circular File - ${circular.title}`}
                className="file-image"
              />
              <hr className="separator" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StudentCircular;
